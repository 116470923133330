import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Card, Form, Input, Radio } from 'antd';
import { DeviceUUID } from 'device-uuid';
import swal from "sweetalert";

const Login = () => {
  const uuid = new DeviceUUID().parse();

  const loginFunction = async (values) => {

    const URL = window.location.href.startsWith("http://localhost")
      ? "http://localhost:5000/api/rol/user/login"
      : " /api/rol/user/login";

    await fetch(URL, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        "Accept": 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "email": values.email,
        "password": values.password,
      })
    })
      .then(async response => {
        try {
          if (response.status !== 200) {
            console.log("Not 200")
          } else {
            const data = await response.json()
            if (data.error === true) {
              swal("Login Failed", data.message);
            } else {
              swal("Logged In successfully", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                localStorage.setItem("data", JSON.stringify(data.token));
                window.location.href = "/"
              });
            }
          }
        } catch (err) {
          console.log(err);
        };
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <>
      <div className="m-5">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <Card title="Login" style={{ boxShadow: "0px 0px 3px #00000080", backgroundColor: "#ededed" }}>
              <Form name="sign up"
                labelCol={{
                  span: uuid.platform === "Android" || uuid.platform === "iPhone" ? 12 : 6
                }}
                wrapperCol={{
                  span: uuid.platform === "Android" || uuid.platform === "iPhone" ? "100%" : 14,
                }}
                layout={uuid.platform === "Android" || uuid.platform === "iPhone" ? "vertical" : "horizontal"}
                onFinish={loginFunction}
                autoComplete="off">

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide Email field!',
                    },
                    {
                      type: 'email',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label=" Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide Password field!',
                    },
                  ]}
                >
                  <Input.Password visibilityToggle={{ visible: false}} />
                </Form.Item>

                <Form.Item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button type="primary" htmlType="submit" style={{ fontWeight: "500" }}>
                    LOGIN
                  </Button>
                </Form.Item>

              </Form>
              <div className="d-flex justify-content-center">
              <a href="forgot-password" style={{textDecoration:'none',fontWeight:'500',textShadow:'1px 1px 4px #CCC'}}>Forgot Password ?</a>
              </div>

            </Card>
          </div>
          <div className="col-lg-2"></div>
        </div>


      </div>

    </>
  );
};

export default Login;
