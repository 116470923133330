import React from 'react'
import teamOne from '../assests/images/teamOne.jpg'
import teamTwo from '../assests/images/teamTwo.jpg'
import teamThree from '../assests/images/teamThree.jpg'
import bijusir from '../assests/images/biju_pegu.png'
import './userCard.css'
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const UserCard = () => {
    return (
        <div className="clearfix">
            <div className="row">
                
                <div className="col-sm-6 col-md-3 m-auto my-2 animated fadeIn">
                    <div className="card">
                        <div className="card-body">
                            <div className="avatar">
                                <img
                                    src={teamOne}
                                    className="card-img-top"
                                    alt=""
                                />
                            </div>
                            <h5 className="card-title">
                                Priyanka Verma
                            </h5>
                            <p className="card-text">
                                <br /> Ph.D. Scholar <br /> Department of Kriya Sharir Faculty of Ayurveda IMS,BHU
                            </p>
                            <hr />
                            <div className='footer-alignment'>
                                <i className='footer--social-back'><FontAwesomeIcon icon={faFacebook} className="footer-icon" /> </i>
                                <i><FontAwesomeIcon icon={faTwitter} className="footer-icon" /> </i>
                                <i ><FontAwesomeIcon icon={faLinkedin} className="footer-icon" /> </i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-3 m-auto my-2  animated fadeIn">
                    <div className="card">
                        <div className="card-body">
                            <div className="avatar">
                                <img
                                    src={teamTwo}
                                    className="card-img-top"
                                    alt=""
                                />
                            </div>
                            <h5 className="card-title">
                                Dr.Rashi Sharma
                            </h5>
                            <p className="card-text">
                                Supervisor <br />Associate Professor <br /> Department of Kriya Sharir Faculty of Ayurveda IMS,BHU
                            </p>
                            <hr />
                            <div className='footer-alignment'>
                                <i className='footer--social-back'><FontAwesomeIcon icon={faFacebook} className="footer-icon" /> </i>
                                <i><FontAwesomeIcon icon={faTwitter} className="footer-icon" /> </i>
                                <i ><FontAwesomeIcon icon={faLinkedin} className="footer-icon" /> </i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-3 m-auto my-2  animated fadeIn">
                    <div className="card">
                        <div className="card-body">
                            <div className="avatar">
                                <img
                                    src={teamThree}
                                    className="card-img-top"
                                    alt=""
                                />
                            </div>
                            <h5 className="card-title">
                                Dr.N S Rajput
                            </h5>
                            <p className="card-text">
                                External Supervisor <br /> Associate Professor <br /> Department of Electronics Engineering IIT,BHU
                            </p>
                            <hr />
                            <div className='footer-alignment'>
                                <i className='footer--social-back'><FontAwesomeIcon icon={faFacebook} className="footer-icon" /> </i>
                                <i><FontAwesomeIcon icon={faTwitter} className="footer-icon" /> </i>
                                <i ><FontAwesomeIcon icon={faLinkedin} className="footer-icon" /> </i>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-sm-6 col-md-3 m-auto my-2  animated fadeIn"> */}
                    {/* <div className="card">
                        <div className="card-body">
                            <div className="avatar">
                                <img
                                    src={bijusir}
                                    className="card-img-top"
                                    alt=""
                                />
                            </div>
                            <h5 className="card-title">
                                Biju Pegu
                            </h5>
                            <p className="card-text">
                                MD & CEO at Gratia Technology Private Limited | Head : Cyber Security | Certified Ethical Hacker | ECSA | CHFI | OSCP | CISA (ISACA) | MCA | M.Tech (IT) | MBA (International Business)
                            </p>
                            <hr />
                            <div className='footer-alignment'>
                                <a href="https://www.facebook.com/bijupeguofficial" target="_blank"><i className='footer--social-back'><FontAwesomeIcon icon={faFacebook} className="footer-icon" /> </i></a>
                                <a href="https://twitter.com/BijuPeguAssam" target="_blank"><i><FontAwesomeIcon icon={faTwitter} className="footer-icon" /> </i></a>
                                <a href="https://www.linkedin.com/in/biju-pegu-776085113" target="_blank"><i ><FontAwesomeIcon icon={faLinkedin} className="footer-icon" /> </i></a>
                            </div>
                        </div>
                    </div> */}
                {/* </div> */}


            </div>
        </div>
    )
}

export default UserCard