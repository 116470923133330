import React from 'react';
import { Button, Card, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import '../tratak/Landing.css'

import logo from '../assests/images/logo.png';

function Landing() {

const navigate = useNavigate()


const loginFunction = async (values) => {

  const URL = window.location.href.startsWith("http://localhost")
    ? "http://localhost:5000/api/rol/user/gototratak"
    : "/api/rol/user/gototratak";

  await fetch(URL, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      "Accept": 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "email": values.email
    })
  })
    // .then(async response => {
    //   try {
    //     if (response.status !== 200) {
    //       console.log("Not 200")
    //     } else {
    //       const data = await response.json()
    //       if (data.error === true) {
    //         // error found 
    //         swal("Login Failed", data.message); // firing the swall alert
    //       } else {
    //         swal("Logged In successfully", {
    //           buttons: false,
    //           timer: 2000,
    //         }).then((value) => {
    //           localStorage.setItem("data", JSON.stringify(data.token)); // storing the token in localstorage
    //           window.location.href = "/consent" // navigating to the tratak
    //         });
    //       }
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   };
    // })
    .then(async response => {
  try {
    if (response.status !== 200) {
      console.log("Not 200");
    } else {
      const data = await response.json();
      if (data.error === true) {
        // error found 
        swal("Login Failed", data.message);
      } else {
        // Check if consent has been given
        const hasConsent = localStorage.getItem("hasConsent");

        if (!hasConsent) {
          // First time user logs in, show consent
          swal("Logged In successfully", {
            buttons: false,
            timer: 2000,
          }).then(() => {
            // Save that consent has been given
            localStorage.setItem("hasConsent", "true");
            // Store the token in localStorage
            localStorage.setItem("data", JSON.stringify(data.token));
            // Redirect to the consent page
            window.location.href = "/consent";
          });   
        } else {
          // User has already given consent, redirect to activity page
          swal("Logged In successfully", {
            buttons: false,
            timer: 2000,
          }).then(() => {
            localStorage.setItem("data", JSON.stringify(data.token));
            window.location.href = "/faceverification"; // Change this to the appropriate activity page
          });
        }
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
})

    .catch(err => {
      console.log(err);
    });
}

  return (
    <div className='full-image'>
    <div className="container py-2">
      <div className="row" style={{ minHeight: '75vh' }}>

          <div className='tratak-logo'>
                <img src={logo} alt="BHU Logo" style={{maxWidth:'100%',width:'200px'}}/>
          </div>
{/* 
        <h1 className="my-2 text-center">Tratak Kriya</h1> */}
          
        <div className="col-md-6 my-2 m-auto">
          <div className="card bg-light">
            <Form
              name="sign up"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: '100%',
              }}
              layout="horizontal"
              autoComplete="off"
              onFinish={loginFunction}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please provide Email field!',
                  },
                  {
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ fontWeight: '500' }}
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
          </div>

          
        </div>
      </div>
    </div>
    </div>
  );
}

export default Landing;
