import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { NavLink } from "react-router-dom";
import "../assests/css/nav.css";
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "antd";
import avatar from "../assests/images/avatar.png";
import jwt_decode from "jwt-decode";
import { LogoutOutlined } from "@ant-design/icons";
import swal from "sweetalert";

const Navbar = ({ logCheck }) => {
  let navigate = useNavigate();
  const [user, setUser] = useState("");

  const navigateToLogin = () => {
    navigate("/login");
  };
  const navigateToSignUp = () => {
    navigate("/signup");
  };

  useEffect(() => {
    if (logCheck !== null) {
      const decoded_data = jwt_decode(logCheck);
      setUser(decoded_data.user);
    }
  }, []);

  function imgFuntion() {
    return (
      <>
        <Avatar
          size={30}
          src={
            user.profile_img
              ? `https://tratakkriya.co.in/${user.profile_img}`
              : avatar
          }
        />
        {/* <img src={`https://tratakkriya.co.in/${user.profile_img}`} /> */}
      </>
    );
  }

  const logoutFunc = () => {
    swal("Are you sure, you want to Logout ?", {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value === true) {
        localStorage.removeItem("data");
        window.location.href = "/";
      }
    });
  };

  // const navigateToProfile = () => {
  //   navigate('/user/profile');
  // };

  const checkScreeningTest = () => {
    const logCheck = localStorage.getItem("data");
    if (logCheck) window.location.href = "/test";
    else window.location.href = "/login";
  };

  return (
    <>
      <nav className="container-nav navbar navbar-expand-lg navbar-light nav-back-color ">
        <NavLink className="navbar-brand" to="#">
          <p className="logoHeader">Tratak Kriya</p>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <div className="nav-lists w-100">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/quick">
                  Quick Survey
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/blogs">
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/significance">
                  Significance
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Resources
                </a>
                <div
                  className="dropdown-menu "
                  aria-labelledby="navbarDropdown"
                >
                  <NavLink className="nav-link dropdown-nav" to="/paper">
                    Paper
                  </NavLink>
                  <NavLink className="nav-link dropdown-nav" to="/book">
                    Book
                  </NavLink>
                  <NavLink
                    className="nav-link dropdown-nav"
                    to="https://drive.google.com/file/d/10xI4BckJRaB1_7iWxgjs6_VuveuqLPpB/view"
                  >
                    User Guide
                  </NavLink>
                </div>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/glossary">
                  FAQ
                </NavLink>
              </li>
              {logCheck ? (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/test">
                    START SCREENING TEST
                  </NavLink>
                </li>
              ) : (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">
                    START SCREENING TEST
                  </NavLink>
                </li>
              )}

              {/* <li className="nav-item">
                <NavLink className="nav-link" onClick={() => checkScreeningTest()}>
                {/* <button type="button" className="btn btn-primary btn-sm text-uppercase" ></button> *
                  START SCREENING TEST
                </NavLink>
              </li> */}
              {logCheck && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={() => logoutFunc()}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <LogoutOutlined style={{ paddingRight: "5px" }} />
                    Logout
                  </NavLink>
                </li>
              )}
              {logCheck && (
                <li className="nav-item">
                  <NavLink to="/user/profile">
                    <Avatar
                      size={40}
                      style={{ background: "#0004", paading: 5 }}
                      src={
                        user.profile_img
                          ? "https://tratakkriyapractice.co.in" +
                            user.profile_img
                          : avatar
                      }
                    />
                  </NavLink>
                </li>
              )}
              {!logCheck && (
                <li className="nav-item">
                  <NavLink to="/login">
                    <button
                      className="btn btn-outline-primary me-2"
                      onClick={() => navigateToLogin()}
                    >
                      Login
                    </button>
                  </NavLink>
                </li>
              )}
              {!logCheck && (
                <NavLink to="/signup">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigateToSignUp()}
                  >
                    Sign-up
                  </button>
                </NavLink>
              )}
            </ul>
          </div>
          {/*logCheck === null ? (
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ width: '100%' }}
            >
              <button
                className="btn btn-outline-primary me-2"
                onClick={() => navigateToLogin()}
              >
                Login
              </button>
              <button
                className="btn btn-primary"
                onClick={() => navigateToSignUp()}
              >
                Sign-up
              </button>
            </div>
          ) : user.role === 0 ? (
            <div
              style={{
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'row',
              }}
            >
              {/* <Button style={{ width: "50px",height:'50px',borderRadius:'50%' }} onClick={() => navigateToProfile()}> */}
          {/* <span style={{ marginLeft: "5px", fontWeight: '500' }}>Welcome {user.first_name} !</span> */}
          {/* <Button onClick={() => navigateToProfile()}>
              <Avatar size={40}  style={{background:'#0004',paading:5}} src={
                    user.profile_img
                      ? "https://tratakkriya.co.in/" + user.profile_img
                      : avatar
                  } />
              </Button> */}
          {/* </Button> */}
          {/* <div onClick={() => logoutFunc()} style={{cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center'}}>Logout <LogoutOutlined style={{ paddingBottom: "30px" }} /></div> */}
          {/* // <Button onClick={() => logoutFunc()} type="primary" icon={<LogoutOutlined style={{ paddingBottom: "30px" }} />} style={{ margin: "5px" }} /> *}
            </div>
          ) : null*/}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
