import { Button, Card, Form, Input } from 'antd'
import Password from 'antd/es/input/Password';
import React, { useState } from 'react'

function ForgotPassword() {
  const [showOtp,setShowOtp] = useState(false);
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [confirm,setConfirm] = useState('');
  const [otp,setOtp] = useState('');
  const [showerr,setShowerr] = useState(false);

  const requestOtp = () => {
    if(password === confirm){
      alert("clicked");
      setShowOtp(true);
      setShowerr(false);
      // request 3rd party api to sent an otp via email or phone

    }
    else{
      setShowerr(true);
      setShowOtp(false);
    } 
  }

  return (
    <>
      <div className="m-5">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <Card title="Login" style={{ boxShadow: "0px 0px 3px #00000080", backgroundColor: "#ededed" }}>
              <Form name="sign up"
                labelCol="Android"
                wrapperCol="100"
                layout="vertical"
                onFinish={() => console.log("clicked")}
                autoComplete="off">

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide Email field!',
                    },
                    {
                      type: 'email',
                    },
                  ]}
                >
                  <Input value={email} onChange={(e)=>setEmail(e.target.value)} />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Enter new Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide new password !',
                    }
                  ]}
                >
                  <Input value={password} onChange={(e)=>setPassword(e.target.value)} />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide new password !',
                    }
                  ]}
                >
                  <Input value={confirm} onChange={(e)=>setConfirm(e.target.value)} />
                </Form.Item>
                
                {showerr && (
                  <p className='text-danger text-center'>Password Does not Matched !</p>
                )}

                {!showOtp && ( 
                <Form.Item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button type="primary" htmlType="submit" style={{ fontWeight: "500" }} onClick={()=>requestOtp()}>
                    Get OTP
                  </Button>
                </Form.Item>
                )}


                {showOtp && (
                <>
                <Form.Item
                  name="otp"
                  label="Enter OTP"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide OTP!',
                    }
                  ]}
                >
                  <Input value={otp} onChange={(e)=>setOtp(e.target.value)} /><br/><br/>
                </Form.Item>


                <Form.Item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button type="primary" htmlType="submit" style={{ fontWeight: "500" }}>
                    LOGIN
                  </Button>
                </Form.Item>
                </>
                )}

              </Form>
            </Card>
          </div>
          <div className="col-lg-3"></div>
        </div>


      </div>
    </>
  )
}

export default ForgotPassword