import swal from "sweetalert";

export const apiCall = async (apiEndpoints, sendData) => {
  const URL = window.location.href.startsWith("http://localhost")
    ? "http://localhost:5000" + apiEndpoints
    : apiEndpoints;
  let data = null;
  await fetch(URL, {
    method: "POST",
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sendData),
  })
    .then(async (response) => {
      try {
        if (response.status !== 200) {
          console.log("Not 200");
        } else {
          data = await response.json();
        }
      } catch (err) {
        console.log(err);
        data = err;
      }
    })
    .catch((err) => {
      swal("Network Error", {
        buttons: false,
        timer: 2000,
      });
    });
  return data;
};
