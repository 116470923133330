import React from "react";
import { useState } from "react";
import swal from "sweetalert";
import { apiCall } from "../api/apiclient";
import "../assests/css/QuickSurvey.css";
import { states } from "../glossary_Data/States";

export const QuickSurvey = () => {
  const [user, setUser] = useState({
    name: "",
    age: "",
    mobile: "",
    email: "",
    education: "",
    institute: "",
    gender: "",
    occupation: "",
    state: "",
    location: "",
    nine: [],
    ten: "",
    eleven: "",
    twelve: [],
    thirteen: "",
    forteen: "",
  });

  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [education, setEducation] = useState("");
  const [institute, setInstitute] = useState("");
  const [gender, setGender] = useState("");
  const [occupation, setOccupation] = useState("");
  const [state, setState] = useState("");
  const [location, setLocation] = useState("");
  const [yoga, setYoga] = useState([]);
  const [ten, setTen] = useState("");
  const [eleven, setEleven] = useState("");
  const [twelve, setTwelve] = useState([]);
  const [thirteen, setThirteen] = useState("");
  const [forteen, setForteen] = useState("");

  const [formerror, setFormError] = useState({});

  const handleInput = (e) => {
    if (e.target.name === "nine") {
      let copy = { ...user };
      if (e.target.checked) {
        copy.nine.push(e.target.value);
      } else {
        copy.nine = copy.nine.filter((el) => el !== e.target.value);
      }
      setUser(copy);
    } else if (e.target.name === "twelve") {
      let copy = { ...user };
      if (e.target.checked) {
        copy.twelve.push(e.target.value);
      } else {
        copy.twelve = copy.twelve.filter((el) => el !== e.target.value);
      }
      setUser(copy);
    } else {
      console.log(e.target.value);
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  const validateForm = () => {
    let err = {};

    if (name === "") {
      err.name = "Name required!";
    }
    if (age === "") {
      err.age = "Date of Birth required!";
    }

    if (mobile === "") {
      err.mobile = "Mobile Number required!";
    } else {
      let regex = /^[6-9]\d{9}$/;
      if (!regex.test(mobile)) {
        err.mobile = "Please provide valid mobile number";
      }
    }

    if (email === "") {
      err.email = "Email required!";
    } else {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(email)) {
        err.email = "Please provide valid email!";
      }
    }

    if (education === "") {
      err.education = "Education required!";
    }
    if (institute === "") {
      err.institute = "Institute required!";
    }
    if (gender === "") {
      err.gender = "Gender required!";
    }
    if (occupation === "") {
      err.occupation = "Occupation required!";
    }
    if (state === "") {
      err.state = "State required!";
    }
    if (location === "") {
      err.location = "Location required!";
    }
    if (ten === "") {
      err.ten = "This field required!";
    }
    if (eleven === "") {
      err.eleven = "This field required!";
    }
    if(yoga == ""){
      err.yoga = "This field required!";
    }
    if (twelve.length < 1) {
      err.twelve = "Any one option required!";
    }
    if (thirteen === "") {
      err.thirteen = "This field required!";
    }
    if (forteen === "") {
      err.forteen = "This field required!";
    }

    console.log(err);

    setFormError({ ...err });

    return Object.keys(err).length < 1;
  };


  const submitForm = (e) => {
    e.preventDefault();
    let isValid = validateForm();
    console.log(isValid);
    if (isValid) {
      // call the function  here
      submitQuickServey();
    }
  };

  const submitQuickServey = async () => {
    const result = await apiCall("/api/survey/addSurvey", {
      full_name: name,
      date_of_birth: age,
      phone_number: mobile,
      email,
      education,
      institute,
      gender,
      occupation,
      state,
      location,
      research: ten,
      prefer_yoga: eleven,
      yoga_online_why: twelve,
      aware_about_trataka: thirteen,
      practice_tratak_kriya: forteen,
      yoga_practices: yoga,
    });
    console.log("Response from server", result);
    if (result) {
      if (result.message === "success") {
        swal("Successfully Submitted", {
          buttons: false,
          timer: 2000,
        }).then((value) => {
          // window.location.href = "/";
        });
      } else if (result.message === "") {
        swal("Failed To Submit", result.message);
      }
    } else {
    }
  };

  return (
    <div
      className="container-fluid py-3"
      style={{ backgroundColor: "#B3BAFF" }}
    >
      <div className="background col-md-5 m-auto">
        <div className="card border-dark  surveyCard ">
          <h4 className="card-header fw-semibold"> Quick Survey</h4>
          <div className="card-body">
            <div className="row">
              <div className="col py-4">
                <form className="form">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label fw-semibold">
                      1. Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.name}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="age" className="form-label fw-semibold">
                      2. Date of Birth
                    </label>
                    <input
                      type="date"
                      name="age"
                      id="age"
                      className="form-control"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                    />
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.age}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile" className="form-label fw-semibold">
                      3. Mobile Number
                    </label>
                    <input
                      type="tel"
                      name="mobile"
                      id="mobile"
                      maxLength="10"
                      className="form-control"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.mobile}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="form-label fw-semibold">
                      4. Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.email}
                    </span>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="education"
                      className="form-label fw-semibold"
                    >
                      5. Education
                    </label>
                    <input
                      type="text"
                      name="education"
                      id="education"
                      className="form-control"
                      value={education}
                      onChange={(e) => setEducation(e.target.value)}
                    />
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.education}
                    </span>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="institute"
                      className="form-label  fw-semibold"
                    >
                      6. Institute
                    </label>
                    <input
                      type="text"
                      name="institute"
                      id="institute"
                      className="form-control"
                      value={institute}
                      onChange={(e) => setInstitute(e.target.value)}
                    />
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.institute}
                    </span>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="gender" className="form-label fw-semibold">
                      7. Gender
                    </label>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="gender"
                        value="Male"
                        onChange={(e) => setGender(e.target.value)}
                        checked={gender === "Male"}
                      />
                      <label htmlFor="maleradio" className="form-check-label">
                        Male
                      </label>
                    </div>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input "
                        name="gender"
                        value="Female"
                        onChange={(e) => setGender(e.target.value)}
                        checked={gender === "Female"}
                      />
                      <label
                        htmlFor="femaleradio"
                        className="form-check-label "
                      >
                        Female
                      </label>
                    </div>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input "
                        name="gender"
                        value="Other"
                        onChange={(e) => setGender(e.target.value)}
                        checked={gender === "Other"}
                      />
                      <label htmlFor="otherradio" className="form-check-label ">
                        Other
                      </label>
                    </div>
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.gender}
                    </span>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="occupation"
                      className="form-label fw-semibold"
                    >
                      8. Occupation
                    </label>
                    <input
                      type="text"
                      name="occupation"
                      id="occupation"
                      className="form-control"
                      value={occupation}
                      onChange={(e) => setOccupation(e.target.value)}
                    />
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.occupation}
                    </span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="state" className="form-label fw-semibold">
                      9. State
                    </label>
                    <select
                      type="text"
                      name="state"
                      id="state"
                      className="form-select"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <option>Please select a state</option>
                      {states.map((el, index) => (
                        <option key={index}>{el}</option>
                      ))}
                    </select>
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.state}
                    </span>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="location"
                      className="form-label fw-semibold"
                    >
                      10. Location
                    </label>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="location"
                        value="Urban Area"
                        onChange={(e) => setLocation(e.target.value)}
                        checked={location === "Urban Area"}
                      />
                      <label htmlFor="urbanradio" className="form-check-label">
                        Urban Area
                      </label>
                    </div>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="location"
                        value="Rural Area"
                        onChange={(e) => setLocation(e.target.value)}
                        checked={location === "Rural Area"}
                      />
                      <label
                        htmlFor="rurbanradio"
                        className="form-check-label "
                      >
                        Rural Area
                      </label>
                    </div>
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.location}
                    </span>
                  </div>
                  <div className="form-group ">
                    <label htmlFor="name" className="form-label fw-semibold">
                      11. Are you practicing any kind of yoga?
                    </label>
                    <div className="check mx-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nine"
                          value="Asana"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setYoga([...yoga, e.target.value]);
                            } else {
                              const rest = yoga.filter(
                                (el) => el !== e.target.value
                              );
                              setYoga(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Asana
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nine"
                          value="Pranayama"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setYoga([...yoga, e.target.value]);
                            } else {
                              const rest = yoga.filter(
                                (el) => el !== e.target.value
                              );
                              setYoga(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Pranayama
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nine"
                          value="Meditation"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setYoga([...yoga, e.target.value]);
                            } else {
                              const rest = yoga.filter(
                                (el) => el !== e.target.value
                              );
                              setYoga(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Meditation
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nine"
                          value="Relaxation (Shavasana)"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setYoga([...yoga, e.target.value]);
                            } else {
                              const rest = yoga.filter(
                                (el) => el !== e.target.value
                              );
                              setYoga(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Relaxation (Shavasana)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nine"
                          value="Shatkarma (Yogic Purification Technique)"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setYoga([...yoga, e.target.value]);
                            } else {
                              const rest = yoga.filter(
                                (el) => el !== e.target.value
                              );
                              setYoga(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Shatkarma (Yogic Purification Technique)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nine"
                          value=" Kapalbhati"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setYoga([...yoga, e.target.value]);
                            } else {
                              const rest = yoga.filter(
                                (el) => el !== e.target.value
                              );
                              setYoga(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Kapalbhati
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="nine"
                          value="No"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setYoga([...yoga, e.target.value]);
                            } else {
                              const rest = yoga.filter(
                                (el) => el !== e.target.value
                              );
                              setYoga(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          No
                        </label>
                      </div>
                      <span style={{ color: "red", fontWeight: "500" }}>
                        {formerror.yoga} 
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name" className="form-label fw-semibold">
                      12. Do you want to be a part of our research?
                    </label>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="ten"
                        value="Yes"
                        onChange={(e) => setTen(e.target.value)}
                        checked={ten === "Yes"}
                      />
                      <label htmlFor="yesradio" className="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input "
                        name="ten"
                        value="No"
                        onChange={(e) => setTen(e.target.value)}
                        checked={ten === "No"}
                      />
                      <label htmlFor="noradio" className="form-check-label ">
                        No
                      </label>
                    </div>
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.ten}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name" className="form-label fw-semibold">
                      13. How do you prefer to practice yoga?
                    </label>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="eleven"
                        value="Virtual(online)"
                        onChange={(e) => setEleven(e.target.value)}
                        checked={eleven === "Virtual(online)"}
                      />
                      <label htmlFor="onlineradio" className="form-check-label">
                        Virtual (Online)
                      </label>
                    </div>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input "
                        name="eleven"
                        value="Physical(Offline)"
                        onChange={(e) => setEleven(e.target.value)}
                        checked={eleven === "Physical(Offline)"}
                      />
                      <label
                        htmlFor="offlineradio"
                        className="form-check-label "
                      >
                        Physical (Offline)
                      </label>
                    </div>
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.eleven}
                    </span>
                  </div>

                  <div className="form-group">
                    <label htmlFor="name" className="form-label fw-semibold">
                      14. If you prefer to practice yoga in online mode. Due
                      to................
                    </label>
                    <div className="check mx-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="twelve"
                          value=" You decide the time, place, and space."
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTwelve([...twelve, e.target.value]);
                            } else {
                              const res = twelve.filter(
                                (el) => el !== e.target.value
                              );
                              setTwelve(res);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          You decide the time, place, and space.
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="twelve"
                          value="Practice yoga during travel."
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTwelve([...twelve, e.target.value]);
                            } else {
                              const rest = twelve.filter(
                                (el) => el !== e.target.value
                              );
                              setTwelve(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Practice yoga during travel.
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="twelve"
                          value="Yoga from the comfort of your home."
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTwelve([...twelve, e.target.value]);
                            } else {
                              const rest = twelve.filter(
                                (el) => el !== e.target.value
                              );
                              setTwelve(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Yoga from the comfort of your home.
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="twelve"
                          value=" Learn and develop a connection with yourself."
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTwelve([...twelve, e.target.value]);
                            } else {
                              const rest = twelve.filter(
                                (el) => el !== e.target.value
                              );
                              setTwelve(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Learn and develop a connection with yourself.
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="twelve"
                          value="Practice yoga at your own place."
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTwelve([...twelve, e.target.value]);
                            } else {
                              const rest = twelve.filter(
                                (el) => el !== e.target.value
                              );
                              setTwelve(rest);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Practice yoga at your own place.
                        </label>
                      </div>
                    </div>
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.twelve}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name" className="form-label fw-semibold">
                      15. Are you aware about Trataka Kriya (Candle gezing)?
                    </label>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="thirteen"
                        value="Yes"
                        onChange={(e) => setThirteen(e.target.value)}
                        checked={thirteen === "Yes"}
                      />
                      <label htmlFor="yradio" className="form-check-label">
                        Yes
                      </label>
                    </div>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input "
                        name="thirteen"
                        value="No"
                        onChange={(e) => setThirteen(e.target.value)}
                        checked={thirteen === "No"}
                      />
                      <label htmlFor="nradio" className="form-check-label ">
                        No
                      </label>
                    </div>
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.thirteen}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name" className="form-label fw-semibold">
                      16. How do you prefer to practice Tratak kriya?
                    </label>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="forteen"
                        value="Virtual (Online)"
                        onChange={(e) => setForteen(e.target.value)}
                        checked={forteen === "Virtual (Online)"}
                      />
                      <label htmlFor="onradio" className="form-check-label">
                        Virtual (Online)
                      </label>
                    </div>
                    <div className="form-check mx-3">
                      <input
                        type="radio"
                        className="form-check-input "
                        name="forteen"
                        value="Physical (Offline)"
                        onChange={(e) => setForteen(e.target.value)}
                        checked={forteen === "Physical (Offline)"}
                      />
                      <label htmlFor="offradio" className="form-check-label ">
                        Physical (Offline)
                      </label>
                    </div>
                    <span style={{ color: "red", fontWeight: "500" }}>
                      {formerror.forteen}
                    </span>
                  </div>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={(e) => submitForm(e)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};
