import React from 'react'
import '../assests/css/footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import logo from '../assests/images/logo.png';

const Footer = () => {
    return (
        <>
            <div className='footer-container'>
                <div className='row justify-content-center'>

                    <div className='col-lg-5'>
                        <h6 className='text-uppercase header'>contact</h6>
                        <ul style={{ listStyle: "none", padding: "0px" }}>
                            <li className='footer-left-list mb-2'><i><FontAwesomeIcon icon={faMapLocationDot} className="footer-icon" /></i>Department of Kriya Sharir,Faculty of Ayurveda,Institute of Medical Sciences,Banaras Hindu University,Varanasi, Uttar Pradesh, India,221005</li>
                            <li className='footer-left-list mb-2'><i><FontAwesomeIcon icon={faEnvelope} className="footer-icon" /></i>priyankaverma@bhu.ac.in</li>
                            <li className='footer-left-list mb-2'><i><FontAwesomeIcon icon={faPhone} className="footer-icon" /></i>+91 8303670309</li>
                        </ul>
                    </div>

                    <div className='col-lg-4'>
                        <h6 className='text-uppercase header'>QUICK LINKS</h6>
                        <ul style={{ listStyle: "none", padding: "0px" }}>
                            <li><a className='footer-list' href='#'>Blogs</a></li>
                            <li><a className='footer-list' href='#'>Significance</a></li>
                        </ul>
                    </div>
                    <div className='col-lg-3 pb-4'>
                        <img src={logo} alt="BHU Logo" style={{maxWidth:'100%',width:'200px'}}/>
                    </div>
                    <hr />
                </div>
                <div className='row f-row'>
                    <div className='col-lg-8'>
                        <p className='copyright-line-footer'>Copyright © 2024 All Rights Reserved by Trataka Kriya.</p>
                        <hr />
                        {/* <p className='footer-created'>Created by, <span><a href="https://gratiatechnology.com/" target="_blank" style={{ fontWeight: 'bold' }}>Gratia Technology Pvt Ltd</a></span></p> */}

                    </div>
                    <div className='col-lg-4 social-alignment'>
                        <i><FontAwesomeIcon icon={faFacebook} className="footer-social-icon" /> </i>
                        <i><FontAwesomeIcon icon={faTwitter} className="footer-social-icon" /> </i>
                        <i><FontAwesomeIcon icon={faEnvelope} className="footer-social-icon" /> </i>
                        <i><FontAwesomeIcon icon={faLinkedin} className="footer-social-icon" /> </i>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Footer