import React from 'react'
import Banner from '../navbar/Banner'
import "../assests/css/pages.css"
import aboutImage from "../assests/images/about.jpg"
import significanceImage from "../assests/images/significance.png"
import UserCard from '../component/UserCard'

const Home = () => {
  return (
    <div>
      <Banner />
      <div className="col-lg-12">
        <div className="title-box text-center mt-5">
          <h6 className="about-research title-sub-title text-primary f-17">
            About Research
          </h6>
        </div>
      </div>

      <div className="row justify-content-center align-items-center row-research mb-5">
        <div className="col-lg-6 img-container">
          <img src={aboutImage} alt="About" className="img-home" />
        </div>
        <div className="col-lg-6 btn-readmore">
          <h3 className="about-header-sig">About Research</h3>
          <p className="about-para">
            Dear participants myself Priyanka Verma Ph.D. Scholar in IMS, BHU,I
            am doing research related to effect of tratak kriya (yogic
            purification technique ) in Computer Vision Syndrome(Eyes related
            symptoms such as dry eye, blurred vision, redness of eye, watering
            of eye) based on Artificial Intelligence. Under supervision of Dr.
            Rashi Sharma and Dr. NS Rajput. assessment of Tratak Kriya on
            Computer Vision Syndrome.....
          </p>
          <button
            type="button"
            className="btn btn-primary text-uppercase screening-btn"
            onClick={() => console.log("asdasd")}
          >
            read more
          </button>
        </div>
      </div>

      {/* significance */}
      <div className="significance-container">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="text-center">
            <h6 className="about-significance title-sub-title text-primary">
              Significance
            </h6>
          </div>
        </div>

        <div className="row justify-content-center align-items-center row-research">
          <div className="col-sm-12 col-md-12 col-lg-6 btn-readmore">
            <h3 className="about-header" >
              Significance
            </h3>
            <p className="about-para">
              At present computers has become an integral part of many
              occupational areas such as schools, colleges and offices, peoples
              are becoming more and more dependent on information technology
              many people work on computers for more than 2 hours a day. Due to
              prolonged use of computer different types of visual symptoms are
              being seen in the person.....
            </p>
            <button
              type="button"
              className="btn btn-primary text-uppercase screening-btn"
              onClick={() => console.log("asdasd")}
            >
              read more
            </button>
          </div>
          <div className="col-lg-6 img-container">
            <img
              src={significanceImage}
              alt="About"
              className="img-significance"
            />
          </div>
        </div>
      </div>

      {/* Our team */}
      <div className="ourteam-container">
        <div className="col-lg-12 ">
          <div className="text-center">
            <h6 className="about-significance title-sub-title text-primary">
              Our Team
            </h6>
          </div>
        </div>
      </div>

      <div className="col-lg-12 ">
        <h6 className="home-research">Our Research Team</h6>
      </div>

      <div style={{ margin: "20px" }}>
        <UserCard />
      </div>
    </div>
  );
}

export default Home
