import React from 'react'
import aboutImage from "../assests/images/about.jpg"

const About = () => {
  return (
    <>
      <div className='about-header-container'>
        <p className='about-header pt-2'>About</p>
        <p className='text-white'>Home / About</p>
      </div>

      <div className='col-lg-12'>
        <div className='title-box text-center mt-5'>
          <h6 className='about-research title-sub-title text-primary f-17'>About</h6>
        </div>
      </div>

      <div className='row justify-content-center align-items-center row-research'>
        <div className='col-lg-6 btn-readmore'>
          <h3 className='about-page-header'>About Research</h3>
          <p className='about-para'>Dear participants myself Priyanka Verma Ph.D. Scholar in IMS, BHU,I am doing research related to effect of tratak kriya (yogic purification technique ) in Computer Vision Syndrome(Eyes related symptoms such as dry eye, blurred vision, redness of eye, watering of eye) based on Artificial Intelligence. Under supervision of Dr. Rashi Sharma and Dr. NS Rajput. assessment of Tratak Kriya on Computer Vision Syndrome.....</p>
        </div>
        <div className='col-lg-6 img-container'>
          <img src={aboutImage} alt="About" className='img-home' />
        </div>

      </div>
    </>
  )
}

export default About