import React from 'react'
import { Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom';

function TratakContainer() {

  
  return (
    <>
      <div className="row">
          <div className="col-lg-12">
            <div className='nav bg-secondary align-items-center'>
             <Link to="/" style={{textDecoration: "none"}}>
             <h1 className='ml-4 text-white'  >Tratak Kriya</h1>
             </Link> 
              <div className="ml-auto d-flex" style={{ display: "flex", justifyContent: "space-between", padding: "25px" }}>
                {/* <div className='nav-link bg-white active'>
                  <strong>Home</strong>
                </div>
                <div className='nav-link bg-white ml-2'>
                  <strong>Assement</strong>
                </div>
                <div className='nav-link bg-white ml-2'>
                  <strong>Contact</strong>
                </div>
                <div className='nav-link bg-white ml-2'>
                  <strong>Feedback</strong>
                </div> */}
                
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <Outlet />
          </div>
          
      </div>
     
    </>
  )
}

export default TratakContainer