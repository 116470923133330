import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Card, Form, Input, Radio, Select, Upload } from 'antd';
import { DeviceUUID } from 'device-uuid';
import { UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { Option } from 'antd/es/mentions';
import { states } from '../glossary_Data/states2';

const Signup = () => {
  const uuid = new DeviceUUID().parse();

  const [fileList, setFileList] = useState([]);

  const [showError, setShowError] = useState(false);

  const [emailError, setEmailError] = useState(null); // mew line for validation

  const [error, setError] = useState('');

  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const [disable,setDisable] = useState(true);

  // file upload function
  const fileUpload = async (uid) => {
    const formData = new FormData();
    formData.append('file', fileList[0]);
    formData.append('uid', uid);

    console.log(fileList);
    console.log(formData);

    const UPLOAD_URL = window.location.href.startsWith('http://localhost')
      ? 'http://localhost:5000/api/upload/profile'
      : '/api/upload/profile';

    await fetch(UPLOAD_URL, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        window.location.href = '/login';
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const onFinish = async (values) => {
    // intercept before posting
    if (password === confirm) {
      if(fileList.length !== 1){
        setShowError(true);
        setError('Please Select a Profile Image !'); 
      }
      else{
        setShowError(false);
        setError('');
        const URL = window.location.href.startsWith('http://localhost')
          ? 'http://localhost:5000/api/rol/user/register'
          : ' /api/rol/user/register';

        await fetch(URL, {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            values: values,
          }),
        })
        .then(async (response) => {
          try {
            if (response.status !== 200) {
              console.log('Not 200');
            } else {
              const data = await response.json();
              fileUpload(data.data.uid);
            }
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    } else {
      setShowError(true);
      setError('Password Does Not Matched !');
    }

    
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const checkEmail = async (email) => {
    setDisable(true);
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      const check = await checkEmailExist(email);
      console.log(check);
      if (check) {
        setEmailError('This email is already Being used !');
        setDisable(true);
      } else {
        setEmailError(null);
        setDisable(false);
      }
    } else {
      setEmailError('Please enter a valid email !');
    }
  };

  const checkEmailExist = async (email) => {
    const URL = window.location.href.startsWith('http://localhost')
      ? 'http://localhost:5000/api/rol/user/checkEmail'
      : '/api/rol/user/checkEmail';

    const result = await fetch(URL, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({"email":email}),
    })
    .then(async (response) => {
      try {
        if (response.status !== 200) {
          console.log('Not 200');
        } else {
          const data = await response.json();
          return data.matched;
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch((err) => {
      console.log(err);
    });
    return result;
  };



  return (
    <>
      <div className="m-5">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <Card
              title="Sign Up"
              style={{
                boxShadow: '0px 0px 3px #00000080',
                backgroundColor: '#ededed',
              }}
            >
              <Form
                name="sign up"
                labelCol={{
                  span:
                    uuid.platform === 'Android' || uuid.platform === 'iPhone'
                      ? 12
                      : 6,
                }}
                wrapperCol={{
                  span:
                    uuid.platform === 'Android' || uuid.platform === 'iPhone'
                      ? '100%'
                      : 14,
                }}
                layout={
                  uuid.platform === 'Android' || uuid.platform === 'iPhone'
                    ? 'vertical'
                    : 'horizontal'
                }
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill First Name field!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="middlename"
                  label="Middle Name"
                  rules={[
                    {
                      required: false,
                      message: 'Please fill middle Name field!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill Last Name field!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  help={emailError}
                  rules={[
                    {
                      required: true,
                      message: 'Please fill Email field!',
                    },
                    {
                      type: 'email',
                    },
                  ]}
                >
                  <Input
                    placeholder="example@email.com"
                    onChange={(e) => checkEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide Phone Number!',
                    },
                    {
                      max: 10,
                      message: 'Phone Number Cannot exceed 10 digits!',
                    },
                  ]}
                >
                  <Input
                    addonBefore="+91"
                    type="number"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="institute"
                  label="Institute"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill Institute field!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select a gender" allowClear>
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="course"
                  label="Course"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill course field!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="age"
                  label="Age"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill age field!',
                    },
                  ]}
                >
                  <Input placeholder="In Years" />
                </Form.Item>
                <Form.Item
                  name="height"
                  label="Height"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill height field!',
                    },
                  ]}
                >
                  <Input placeholder="In Cm" />
                </Form.Item>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill state field!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select State"
                    style={{ fontWeight: 600 }}
                    options={states}
                  />
                    {/* {states.map((el, key) => (
                      <Select.Option style={{ fontWeight: 600 }} value={el} key={key}>
                        {el}
                      </Select.Option>
                    ))} */}
                  {/* </Select> */}
                </Form.Item>

                <Form.Item
                  name="password"
                  label=" Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill Password field!',
                    },
                  ]}
                >
                  <Input.Password
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please fill Password field!',
                    },
                  ]}
                >
                  <Input.Password
                    value={confirm}
                    onChange={(e) => setConfirm(e.target.value)}
                  />
                </Form.Item>

                {/* image upload */}
                <Form.Item label="Upload Profile Picture">
                  <div>
                    <ImgCrop rotate>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        // onChange={onChange}
                        {...props}
                        onPreview={onPreview}
                      >
                        {fileList.length < 1 && '+ Upload'}
                      </Upload>
                    </ImgCrop>

                    {showError && (
                      <div style={{ color: 'red', textAlign: 'center' }}>
                        {error}
                      </div>
                    )}
                  </div>
                </Form.Item>

                <Form.Item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={disable}
                    style={{ fontWeight: '500' }}
                  >
                    REGISTER
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </>
  );
};

export default Signup;
