import React from 'react'
import significanceImage from "../assests/images/significance.png"

const Significance = () => {
    return (
        <>
            <div className='about-header-container'>
                <p className='about-header'>Significance</p>
                <p className='text-white'>Home / Significance</p>
            </div>

            <div className='significance-cont'>
                <div className='col-lg-12 '>
                    <div className='text-center'>
                        <h6 className='about-significance title-sub-title text-primary'>Significance</h6>
                    </div>
                </div>

                <div className='row justify-content-center align-items-center row-research'>
                    <div className='col-lg-6 btn-readmore'>
                        <h3 className='about-page-header'>Significance</h3>
                        <p className='about-para'>At present computers has become an integral part of many occupational areas such as schools,  colleges and offices, peoples are becoming more and more dependent on information technology  many people work on computers for more than 2 hours a day. Due to prolonged use of computer different types of visual symptoms are being seen in the person. Such as a Burning, Itching  ,foreign body sensation ,Tearing ( watering), Excessive blinking ,Eye redness , Eye pain  ,Heaviness of eyelids , Dryness, Blurred vision, Double vision, Difficulty focusing for near  vision, Increased sensitivity to light, Colored halos around objects , Feeling that sight is  worsening, Headache these symptoms associated Symptoms picture Some Video of chronicity of cvs with computer vision syndrome and there is no  previous researches related to artificial intelligence based assessment of Tratak Kriya on  computer vision syndrome.</p>

                    </div>
                    <div className='col-lg-6 img-container'>
                        <img src={significanceImage} alt="About" className='img-significance' />
                    </div>

                </div>
            </div>

        </>
    )
}

export default Significance