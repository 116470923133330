import './App.css';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Navbar from './navbar/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Errorpage from './pages/Errorpage';
import Blogs from './pages/Blogs';
import Significance from './pages/Significance';
import Footer from './navbar/Footer';
import  jwt_decode  from "jwt-decode";
import Mcq from './pages/user/Mcq';
import Csqv from './pages/user/Csqv';
import TestContainer from './Conatiners/TestContainer';
import AdLogin from './admin/adminLogin/AdLogin';
import AdminDashboard from './admin/pages/dashboard/AdminDashboard';
import Dashboard from './admin/pages/dashboard/Dashboard';
import Users from './admin/pages/Users';
import UpdatePersonalDetails from './admin/pages/personal/UpdatePersonalDetails';
import UserProfile from './admin/pages/UserProfile';
import UserProfileDetail from './pages/user/UserProfileDetail';
import Glossary from './pages/user/Glossary';
import ForgotPassword from './pages/ForgotPassword';
import CVSQResult from './admin/pages/CVSQResult';
import ScreeningResult from './admin/pages/ScreeningResult';
import { QuickSurvey } from './pages/QuickSurvey';
import Consent from './pages/Consent';
import TratakContainer from './Conatiners/TratakContainer';
import TActivity from './pages/TActivity';
import { useEffect, useState } from 'react';
import Landing from './tratak/Landing';
import FaceVerification from './pages/FaceVerification';
import Dashboard_user from './userDashboard/pages/Dashbord_user';
import { useContext } from 'react';
// import UserDasebord from './pages/UserDasebord'

function App() {
  const location = useLocation();
  const path = location.pathname;
  const pathName = path.split('/')[1];

  // const [decoded_data, setDecoded_data] = useState();
  const logCheck = localStorage.getItem('data');

 

  // if(consentGiven !=0){
   
  //   return navigate("/consent")
  // }

  // const logCheck = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InJvbGUiOjB9LCJuYW1lIjoiSm9obiBEb2UifQ.fhcWqSQ3fYwN4jzWq5Cm5MPVRCWJD9l3mMO24VludxA';
  // console.log(logCheck);
  let decoded_data;
  if (logCheck !== null) {
    decoded_data = jwt_decode(logCheck);
  }

  let navigate = useNavigate();

  useEffect(() => {
    if(logCheck){
      let decoded_data = jwt_decode(logCheck);
      // setDecoded_data(decoded_data);
    }
    if(decoded_data && decoded_data.user.role === 1){
      // window.location.href="/dashboard";
      return navigate("/dashboard");
    }
   }, [])

  


  const handleConsent = () => {
    const hasConsent = localStorage.getItem("hasConsent") === 'true'
      if (hasConsent == true) {
      return false
      
 } else{
     return true
     
 }
  };


  return (
    <>
      {!logCheck ? (
        <>{pathName !== "tratak" && <Navbar logCheck={logCheck} />}</>
      ) : decoded_data.user.role === 0 ? (
        <>{pathName !== "tratak" && pathName !== "dashboard_user" &&  pathName !== "consent" && <Navbar logCheck={logCheck} />}</>
      ) : null}



      <Routes>
        <Route path="/quick" element={<QuickSurvey />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/significance" element={<Significance />} />
        <Route path="/paper" element={<Home />} />
        <Route path="/book" element={<Home />} />
        <Route path="/glossary" element={<Glossary />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* second phase open to all routes no header and footer */}
        <Route path="/tratak" element={<TratakContainer />}>
          <Route path="" element={<Landing />} />
        </Route>


       

        {!logCheck && <Route path="/admin" element={<AdLogin />} />}
        {logCheck ? (
          decoded_data.user.role === 0 ? (
            // for user
            <>
              <Route path="/user/profile" element={<UserProfileDetail />} />
              <Route path="/test" element={<TestContainer />}>
                <Route path="/test" element={<Mcq />} />
                <Route path="/test/mcq" element={<Mcq />} />
                <Route path="/test/csvq" element={<Csqv />} />
              </Route>

              {/* // protecting the other tratak routes  */}
               {/* second phase Protected routes no header and footer */}

               {/* <Route path="/tratak" element={<TratakContainer />}>
             
               </Route> */}

                  <Route path="/dashboard_user/*" element={<Dashboard_user />}>
                    <Route path="" element={<TActivity />} />
                  </Route>
              
                
              
         {/* <Route path="/consent" element={<Consent/>} />
              {consentGiven ? null : <Route path="/consent" element={<Consent />} />} */}

            

              {!handleConsent() &&<Route path="/consent" element={<TratakContainer />}>
            {!handleConsent() && <Route path="" element={<Consent />} />}
                        </Route>}
              {/* <Route path="/userdasebord" element={<UserDasebord/>} /> */}
              <Route path="/faceverification" element={<FaceVerification />} />
            </>
          ) : (
            // for admin panel
            <Route path="/dashboard/*" element={<Dashboard />}>
              <Route path="" element={<AdminDashboard />} />
              <Route path="users" element={<Users />} />
              <Route path="screeningresult" element={<ScreeningResult />} />
              <Route path="cvsqresult" element={<CVSQResult />} />
              <Route
                path="update/details"
                element={<UpdatePersonalDetails />}
              />
              <Route path="update/profile" element={<UserProfile />} />
            </Route>
          )
        ) : (
          <Route path="/" element={<Home />} />
        )}
        

        <Route path="*" element={<Errorpage />} />
      </Routes>

      {/* {
      pathName ===  'dashboard_user' ? null : (
        
        <Navbar/>)} */}
     {pathName === 'test' ||
      pathName === 'dashboard' ||
      pathName === 'tratak' ||
      pathName === 'consent' ||
      pathName ===  'dashboard_user' ? null : (
        <Footer />
      )}

      
    </>
  );
}

export default App;