import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "./Consent.css";
import Webcam from "react-webcam";
import jsPDF from "jspdf";
import { toPng } from "html-to-image";

import { DeviceUUID } from "device-uuid";
import swal from "sweetalert";

function FaceVerification() {
  const [isLoading, setIsLoading] = useState(false);
  const logCheck = localStorage.getItem("data");
  const decoded_data = jwt_decode(logCheck);
  const [sign, setSign] = useState();
  const [signUrl, setSignUrl] = useState();
  const [consentGiven, setConsentGiven] = useState(false);
  const [faceVerified, setFaceVerified] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const Navigate = useNavigate();
  const webcamRef = useRef(null);

  const handleConsentChange = () => {
    setConsentGiven(!consentGiven);
  };

  const [userID, setUserID] = useState({
    USER: decoded_data.user.uid,
  });
  // console.log(decoded_data.user)
  // console.log(decoded_data.user.uid)
  // console.log(decoded_data)
  console.log(userID);

  const videoConstraints = {
    width: { min: 480 },
    height: { min: 480 },
    aspectRatio: 1.0,
    facingMode: "user",
  };

  const captureScreenshot = async () => {
    const screenshotData = webcamRef.current.getScreenshot();
    console.log("successfully captured", screenshotData);
  };

  const getSingleUserDetails = async () => {
    //get the data
    const _data = await JSON.parse(localStorage.getItem("data"));
    const URL = window.location.href.startsWith("http://localhost")
      ? "http://localhost:5000/api/user/get/user/data"
      : " /api/user/get/user/data";

    await fetch(URL, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tokens: _data,
        uid: decoded_data.user.uid,
      }),
    })
      .then(async (response) => {
        try {
          if (response.status !== 200) {
            console.log("Not 200");
          } else {
            const data = await response.json();
            console.log(data);
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uuid = new DeviceUUID().parse();
  useEffect(() => {
    getSingleUserDetails();
  }, []);

  const onFinish = async () => {
    const _data = await JSON.parse(localStorage.getItem("data"));
    const URL = window.location.href.startsWith("http://localhost")
      ? "http://localhost:5000/api/rol/user/update"
      : "/api/rol/user/update";

    await fetch(URL, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tokens: _data,
        uid: decoded_data.user.uid,
      }),
    });
  };

  const handleButtonClick = () => {
    // store all the information in node backend (sign,userid)
    // skipping previous 4 steps
    // redirect to the tratak at the end
    if (consentGiven) {
      Navigate("/tratak/Activity");
    }
  };

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: "image/png" });
  }
  const sentUserImageForVerify = async () => {
    setIsLoading(true);
    const screenshotData = webcamRef.current.getScreenshot();

    const binaryImageData = dataURItoBlob(screenshotData);

    const AWS = require("aws-sdk");
    const s3 = new AWS.S3({
      accessKeyId: "AKIAQMGZQ33NARKGOAMY",
      secretAccessKey: "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",
      region: "ap-south-1",
    });

    const bucketName = "user-image-for-faceverification1";

    const timestamp = Date.now(); // Format: "YYYY-MM-DDTHH:MM:SS.sssZ"
    const formattedTimestamp = Math.floor(timestamp / 1000);

    const key = `${decoded_data.user.uid}${formattedTimestamp}.png`;

    console.log(key);

    const params = {
      Bucket: bucketName,
      Key: key,
      Body: binaryImageData,
      ContentType: "image/png", // Change the content type if necessary
    };

    // payload = {user_id, captured_img}

    try {
      const response = await s3.upload(params).promise();
      console.log("Successfully uploaded screenshot:", response.Location);

      axios
        .post("https://tratakml.tratakkriyapractice.co.in/faceAuth", userID)
        .then((result) => {
          console.log(result);

          if (result.data == 920) {
            swal({
              title: "Face Verified",
              icon: "success",

              // buttons: true, // Hide the "OK" button
            });

            Navigate("/dashboard_user");
          } else {
            swal({
              title: "Face Not Verified",
              text: "Oops! Your Face is not matched. Please try again.",
              icon: "error",
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error during face verification:", error);
          swal({
            title: "Network Error",
            text: "An error occurred while verifying your face. Please try again later.",
            icon: "error",
          });
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error uploading image to S3:", error);
    }
    setIsLoading(true);
    setFaceVerified(true);
  };

  const selectImage = (e) => {
    setSign(e);
    var img = URL.createObjectURL(e);
    setSignUrl(img);
  };

  function toDataURL(src, callback) {
    var image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL("image/jpeg");
      callback(dataURL);
    };
    image.src = src;
  }

  return (
    <>
      <div className="container">
        <div className="row my-4">
          <>
            <Webcam
              width={480}
              ref={webcamRef}
              height={480}
              videoConstraints={videoConstraints}
              audio={false}
              imageSmoothing={true}
              mirrored={true}
              screenshotFormat="image/jpeg"
            />
            <div class="d-flex justify-content-center w-100">
              {isLoading && (
                <div className="loading-screen">Please Wait...</div>
              )}
              <button
                className="btn btn-primary my-4"
                onClick={() => sentUserImageForVerify()}
              >
                User Face Verification
              </button>
            </div>
          </>

          {/* <div className="col-md-6">
            {consentGiven && sign ? (
              <>
                <div class="d-flex justify-content-center w-100">
                <Webcam
                  width={480}
                  ref={webcamRef}
                  height={480}
                  videoConstraints={videoConstraints}
                  audio={false}
                  imageSmoothing={true}
                  mirrored={true}
                />
                </div>
                <div class="d-flex justify-content-center w-100">
                  <button className="btn btn-primary my-4" onClick={()=>sentUserImageForVerify()}>User Face Verification</button>
                </div>
                
              </>
            ) : (
              <>
                <h1>Instructions</h1>
                <p>aise karna hai </p>
                <p>aise karna hai </p>
                <p>aise karna hai </p>
              </>
            )}
          </div>
          <div className="col-md-12">
            {consentGiven && faceVerified && (
              <div className="w-100 d-flex justify-content-center">
                <button
                  className="btn btn-success btn-lg m-auto"
                  onClick={() => generatePDF()}
                >
                  Download PDF & Proceed
                </button>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default FaceVerification;
