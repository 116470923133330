import React, { useState, useEffect } from 'react'
import {
    Form,
    Input,
} from 'antd';
import { DeviceUUID } from 'device-uuid';
import '../asset/css/userprofile.css'
import avatar from '../asset/images/avataar.png'
import { Avatar } from 'antd'

const UserProfile = ({ user_id }) => {


    const [user, setUser] = useState("");

    const getSingleUserDetails = async () => {
        //get the data
        const _data = await JSON.parse(localStorage.getItem('data'))
        const URL = window.location.href.startsWith("http://localhost")
            ? "http://localhost:5000/api/user/get/user"
            : " /api/user/get/user";

        await fetch(URL, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "tokens": _data,
                "uid": user_id
            })
        })
            .then(async response => {
                try {
                    if (response.status !== 200) {
                        console.log("Not 200")
                    } else {
                        const data = await response.json()
                        console.log(data);
                        setUser(data.data[0])
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    };


    useEffect(() => {
        getSingleUserDetails()
    }, [])
    const [componentDisabled, setComponentDisabled] = useState(false);
    const uuid = new DeviceUUID().parse();

    return (
        <>
            <div className='row'>
                <div className='col-lg-2'>
                    <div className='p-img-container'>
                        <Avatar
                            className="profile-image"
                            sx={{ height: "120px", width: "120px", marginBottom: "5px", marginTop: "10px" }}
                            alt="profile_image"
                            src={
                                user.profile_img
                                    ? "http://" + user.profile_img
                                    :
                                    avatar
                            }
                        />
                        <div className="data mt-3">
                            <p>{user.first_name ? user.first_name : "NA"}</p>
                            <p>{user.created_at ? user.created_at : "NA"}</p>
                        </div>
                    </div>

                </div>
                <div className='col-lg-10 p-2'>
                    <div className='second-container'>

                        {/* <label htmlFor="" >Name: </label>
                        <input type="text" value={user.first_name} width="200px" /> */}


                        <Form name="profile"
                            labelCol={{
                                // span: uuid.platform === "Android" || uuid.platform === "iPhone" ? 10 : 5
                            }}
                            wrapperCol={{
                                span: uuid.platform === "Android" || uuid.platform === "iPhone" ? "100%" : "20em",
                            }}
                            layout={uuid.platform === "Android" || uuid.platform === "iPhone" ? "vertical" : "vertical"}
                            // onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            // layout='inline'
                            autoComplete="off"
                            fields={[
                                {
                                    name: ["firstName"],
                                    value: user.first_name,
                                },
                                {
                                    name: ["lastName"],
                                    value: user.last_name,
                                },
                                {
                                    name: ["email"],
                                    value: user.email,
                                },
                                {
                                    name: ["phone"],
                                    value: user.phone,
                                },
                                {
                                    name: ["gender"],
                                    value: user.gender,
                                },
                                {
                                    name: ["age"],
                                    value: user.age,
                                },
                                {
                                    name: ["institute"],
                                    value: user.institute,
                                },
                                {
                                    name: ["course"],
                                    value: user.course,
                                },
                                {
                                    name: ["state"],
                                    value: user.state,
                                }
                            ]}
                        >

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                                <Form.Item
                                    name="firstName"
                                    label="First Name">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>
                                <Form.Item
                                    name="lastName"
                                    label="Last Name">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label="Email">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                                <Form.Item
                                    name="phone"
                                    label="Phone Number">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>

                                <Form.Item
                                    name="gender"
                                    label="Gender">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>

                                <Form.Item
                                    name="age"
                                    label="Age">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>


                            </div>


                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                                <Form.Item
                                    name="institute"
                                    label="Institute">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>
                                <Form.Item
                                    name="course"
                                    label="Course">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>
                                <Form.Item
                                    name="state"
                                    label="State">
                                    <Input disabled={!componentDisabled} />
                                </Form.Item>
                            </div>

                        </Form>

                    </div>
                </div>

            </div>
        </>
    )
}

export default UserProfile