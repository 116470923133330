import React, { useState, useRef, useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import {
    DownloadOutlined
} from '@ant-design/icons';
import { useSearchParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import '../asset/css/user.css'
import UserProfile from './UserProfile';

function ScreeningResult() {
    const [empData, setEmpData] = useState([])
    const [tempData, setTempData] = useState([])
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const viewprofile = searchParams.get("user_id") || false;

    // Table stuff
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [openModal, setOpenModal] = useState(false);
    const [modalData,setModalData] = useState(null);  

    const [exportData,setExportData] = useState([]);  

    const getUsersData = async () => {
        //get the data
        setLoading(true)
        const _data = await JSON.parse(localStorage.getItem('data'))
        const URL = window.location.href.startsWith("http://localhost")
            ? "http://localhost:5000/api/cvsqresponse/get/getcvsqreport"
            : "/api/cvsqresponse/get/getcvsqreport";

        await fetch(URL, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "tokens": _data,
            })
        })
            .then(async response => {
                try {
                    if (response.status !== 200) {
                        setLoading(false)
                        console.log("Not 200")
                    } else {
                        const data = await response.json()
                        reStructure(data.data);
                        setLoading(false)
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        getUsersData()
    }, [])

    const fetchResponses = async (user) => {
      console.log('Fetch data and open modal ');

      const _data = await JSON.parse(localStorage.getItem('data'));
      const URL = window.location.href.startsWith('http://localhost')
        ? 'http://localhost:5000/api/cvsqresponse/get/cvsqresponsebyuser'
        : '/api/cvsqresponse/get/cvsqresponsebyuser';

      await fetch(URL, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          u_id: user.u_id,
        }),
      })
        .then(async (response) => {
          try {
            if (response.status !== 200) {
              console.log('Not 200');
              setOpenModal(true);
            } else {
              const data = await response.json();
              setModalData({ user, data });
              setOpenModal(true);
            }
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    };

    const reStructure = (tempData) => {
        // restructure data 
        const data = [];
        tempData.map((el)=>{
            const { userDetails, ...rest} = el;
            const user = userDetails[0]
            const newEl = {...rest, ...user};
            const {u_id, final_result, result_status, failed_severity, uid, first_name, middle_name, last_name, email, phone, ...ob} = newEl;
            const final = {u_id, final_result, result_status, failed_severity, uid, first_name, middle_name, last_name, email, phone}
            // console.log(final);
            data.push(final);
        });
        setEmpData(data);
        setLoading(false);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'User ID',
            dataIndex: 'uid',
            key: 'uid',
            ...getColumnSearchProps('uid'),
            render: (text, record, index) => {
                return (
                    <a
                        style={{ color: "blue" }}
                        id={record.uid}
                        onClick={e => {
                            window.location =
                                "?user_id=" + record.uid
                        }}
                        size="large"
                    >{text}</a>

                );
            },
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
            ...getColumnSearchProps('first_name'),
            render: (text, record, index) => {
                return (
                    <a
                        style={{ color: "blue" }}
                        id={record.uid}
                        onClick={e => {
                            window.location =
                                "?user_id=" + record.uid
                        }}
                        size="large"
                    >{text}</a>

                );
            },
        },
        {
            title: 'Middle Name',
            dataIndex: 'middle_name',
            key: 'middle_name',
            ...getColumnSearchProps('middle_name'),
            render: (text, record, index) => {
                return (
                    <a
                        style={{ color: "blue" }}
                        id={record.uid}
                        onClick={e => {
                            window.location =
                                "?user_id=" + record.uid
                        }}
                        size="large"
                    >{text}</a>

                );
            },
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
            ...getColumnSearchProps('last_name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            ...getColumnSearchProps('phone'),
            // sorter: (a, b) => a.phone.length - b.phone.length,
            // sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Result',
            dataIndex: 'result_status',
            key: 'phone',
            ...getColumnSearchProps('phone'),
            render:(record) => (
                <>
                  {record === 1 ? 'Passed' : 'Failed'}
                </> 
            ),

        },
        {
            title: 'View Responses',
            dataIndex: 'action',
            dataIndex: 'action',
            render: (text, record, index) => (
                <>
                  <button className="btn btn-warning btn-sm btn-outlined" onClick={()=>fetchResponses(record)}>View Responses</button>
                </>
            ),
        },
    ];

    const stripHtml = (html) => {
        const regex = /(<([^>]+)>)/ig;
        return html.replace(regex, '');
    }

    const ExportData = (data) => {
        if(data){
            const newD = []
            data.map((el,index)=>{
                const r = {};
                r.SlNo = index+1;
                if(el.q_id) { r.question = stripHtml(el.q_id.questions)} else { r.question = 'Undefined Question'}
                if(el.frequency == 0) { r.frequency = 'Never'} else if (el.frequency == 1) { r.frequency = 'Occassionaly'} else r.frequency = 'Often/Always'
                if(el.intensity == 1) { r.intensity = 'Moderate'}  else { r.intensity = 'Intense' }
                r.mark = el.mark
                newD.push(r);                
            })
            setExportData(newD)
        } 
    }
    console.log(exportData);

    return !viewprofile ? (
      <>
        <div className='user-container'>
            <div className='user-header-container'>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ marginRight: "10px", fontSize: "16px", fontWeight: "600" }}>Total Users:</div>
                    <div style={{ marginRight: "10px", fontSize: "16px", fontWeight: "600" }}>{empData ? empData.length : "-"}</div>
                </div>

                <CSVLink
                    filename={"users.csv"}
                    data={empData}
                    className="btn btn-primary"
                    style={{ fontSize: "13px", fontWeight: "600" }}
                    onClick={() => {
                    }}>
                    <DownloadOutlined style={{ marginRight: "10px", fontSize: "15px" }} /> EXPORT ALL
                </CSVLink>

            </div>
            <Table
                columns={columns}
                dataSource={empData}
                rowKey={(record) => record._id}
                bordered
                loading={loading}
            />
        </div>
        
        {openModal && (
        <Modal
        title={`Screening Responses`}
        open={openModal}
        // confirmLoading={}
        width={'70%'}
        onOk={()=>setOpenModal(false)}
        onCancel={()=>{console.log('close');setOpenModal(false)}}
        >
            <div className='d-flex justify-content-between align-items-center'>
                <h3 style={{textTransform:'capitalize'}}>{modalData.user.first_name} {modalData.user.last_name}</h3>
                {exportData && exportData.length > 0 && (
                    <CSVLink
                    filename={"users.csv"}
                    data={exportData}
                    className="btn btn-primary"
                    style={{ fontSize: "10px", fontWeight: "600" }}
                    onClick={() => {
                    console.log(modalData.data.data);
                    }}>
                    <DownloadOutlined style={{ marginRight: "10px", fontSize: "15px" }} /> EXPORT
                    </CSVLink>
                )}
                
                <button
                  onClick={()=>ExportData(modalData.data.data)} 
                  className="d-flex align-items-center btn-primary"
                >
                  <DownloadOutlined/> Prepare Report
                </button>
            </div>
            <table className='table w-100'>
                <thead>
                    <tr>
                        <td>Sl No</td>
                        <td>Question</td>
                        <td>Frequency</td>
                        <td>Intensity</td>
                        <td>Total Marks</td>
                    </tr>
                </thead>
                <tbody>
                {modalData && modalData.data.data.map((el,index) => (
                    <tr>
                        <td>{index + 1}</td>
                        <td>{el.q_id ? stripHtml(el.q_id.questions) : 'Undefined Question'}</td>
                        <td>{el.frequency == 0 ? 'Never' : el.frequency == 1 ? 'Occassionaly' : 'Often/Always'}</td>
                        <td>{el.intensity == 1 ? 'Moderate' : 'Intense'}</td>
                        <td>{el.mark}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </Modal>

)}

      </>
    )
        :
        <UserProfile user_id={viewprofile} />
}

export default ScreeningResult