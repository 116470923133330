import React from 'react';
import blogImage1 from '../assests/images/blogs1.jpg';
import blogImage2 from '../assests/images/blogs2.jpg';
import blogImage3 from '../assests/images/blogs3.jpg';
import blogImage4 from '../assests/images/blogs4.jpg';

const Blogs = () => {
  return (
    <>
      <div className="about-header-container">
        <p className="about-header">Our Blogs</p>
        <p className="text-white">Home / Blogs</p>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box text-center mt-5">
              <h6 className=".blog-text title-sub-title text-primary f-17">
                Blogs
              </h6>
            </div>
          </div>

          <div className="col-md-2"></div>
          <div className="col-md-2">
            <img src={blogImage1} style={{ width: '100%', margin: '' }} />
          </div>
          <div className="col-md-4">
            <div className="title-box text-center mt-5">
              <h3 className="blog-text mt-4">TRATAKA</h3>
            </div>
          </div>
          <div className="col-md-2">
            <img src={blogImage2} style={{ width: '80%', margin: 'auto' }} />
          </div>
          <div className="col-md-2"></div>

          <div className="col-md-9" style={{ margin: '4em auto 0 auto' }}>
            <p className="about-para mt-3">
              Trataka is described as one of the cleansing or purification
              techniques of hatha yoga. Most people know the simple version of
              this technique and believe that Trataka means to gaze at something
              continuously. It is also believed that by practicing Trataka the
              eyes develop a magnetic quality by which anyone can be hypnotized,
              and any type of siddhi can be achieved.{' '}
            </p>
            <p className="about-para mt-3">
              In yoga, however, the practice of Trataka is not seen as a
              technique for achieving siddhis, but as a process purification.
              First, an effort is made to purify the body by of removing toxic
              elements then, in Lauliki, or Nauli Kriya, the aim is to bring the
              subtle aspects of the physical body under control. Now, in the
              practice of Trataka an effort is made to cleanse and calm the
              region of the brain. Organs from the feet to the neck are brought
              under control through the practice of Nauli, and the portion above
              the neck, especially the brain region, is particularly influenced
              through the practice of Trataka.
            </p>

            <p className="text-danger about-para mt-3 text-center">
              <strong>"
                Nimeshonmeshakam tyaktvaa sookshmalakshyam nireekshayet;
                <br />
                Patanti yaavadashrooni traatakam prochyate budhaih. ( Gherana
                Samhita 1/53)
                <br />
                Evamabhyaasayogena shaambhavee jaayate dhruvam; <br />
                Netrarogaa vinashyanti divyadrishtih prajaayate. (Gheranda
                Samhita 1/54) "
              </strong>
            </p>

            <p className="about-para mt-3">
              Gazing at a subtle object without blinking the eyes, until tears
              begin to fall, known as Trataka. With constant practice of
              Trataka, Shambhavi Mudra is achieved, defects of the eyes are
              removed, and divine vision is attained.
            </p>

            <p className="about-para mt-3">
              Trataka means to gaze at a fixed point with steady vision.
              Theoretically, there are three elements in trataka. The first is
              the viewer seated in the place of practice. The second is the view
              of the object in front of the viewer. The third is the process of
              gazing. Once the attention has become steadily aware of these
              three elements, that is a state of internal awareness or
              one-pointed internal consciousness. This practice is to be
              continued until tears start patanti yaavadashrooni. Ashrooni means
              tears. Traatakam prochyate budhaih, Trataka should be thus learnt
              and understood. This is a description of Trataka in coded
              language.
            </p>

            <h3 className='mt-3 text-primary mb-4' style={{}}>Types of Trataka</h3>

            <h5>In Yoga three types Trataka are acknowledged</h5>
            <ol className="about-para mt-3">
              <li className="mt-2" style={{color:'#856'}}>The first is Bahiranga Trataka or Bahir Trataka, External Trataka. </li>
              <li className="mt-2" style={{color:'#856'}}>The second is Antar Trataka or Antaranga Trataka, Inner or Internal Trataka .</li>
              <li className="mt-2" style={{color:'#856'}}>The third type of Trataka is Adho Trataka , which is practiced with the eyes half open and half closed.</li>
            </ol>

            <h5>External Trataka (Bahiranga Trataka)-</h5> 
            <p className="about-para mt-3">For bahir trataka, an object or image which has a for is selected. Everything in the universe, the sun, moon, star trees, an idol or a photograph of the guru can be consider as symbols. One can practice bahir Trataka making use of  such an object, provided one's consciousness merges with it while gazing at it continuously. Choose a symbol, bearing in mind that the symbol should not be changed. Otherwise, the after-image reflected on the screen of the mind would become obsolete, and the practice would need to be started again from the beginning. Also, the full benefits of the practice would not be derived. If one selects a symbol and practices Trataka on it daily, the after- image will start becoming clear. This will help later in the practice of Antar Trataka (Internat Trataka), and chitta, consciousness, will soon become steadily focused on that symbol.Generally people are extroverted by nature, so for many people it is impossible to keep the eyes closed even for two minutes. Bahir Trataka, external Trataka, is very useful here. Through Bahir trataka the eyes are trained to be fixed and steady at one spot, so that the chitta vrittis, the patterns or dispositions of the mind, are changed and can easily be converted into introversion. 
            </p>

            <h5>Antar Trataka (Internal Trataka) –</h5> 
            <p className="about-para mt-3">The eyes are closed and the imaginative power of the mind is awakened. An image is fixed on the inner space of the mind, Chidakasha, and then Trataka is performed on it. For this, an inner or mental symbol can be chosen. A yantra, geometrical diagram, the sun or moon, one's own ishta, deity of choice, or the guru can be used as the symbol. In the beginning, the practice of Antaranga Trataka is possible only after the practice of Bahiranga Trataka. With External Trataka, the practice of concentrating Chitta (mind) at one place is achieved and it helps an inner after-image to emerge. When the afterimage appears clearly on the inner screen, Chitta (mind) immediately becomes one-pointed, because thoughts other than the afterimage are obliterated.   Once this is achieved, Antar Trataka is very effective practice to channel the energy on a definite path. Concentration and the state of meditation are attained with this type of Trataka. People who can easily perceive the image of their symbol mentally can do Antar Trataka without practicing on an external object first. </p>

            <h5>Adho Trataka (Middle Trataka)-</h5>
            <p className="about-para mt-3">practice with the half eyes open and half closed. In Trataka, different positions of the eyes have been studied and named. Open eyes are called Poornima Drishti, full vision. The full moon is also called Poornima. Closed eyes are called Ama Drishti; Ama relates to the word Amavasya, and the no moon or dark moon is referred to as Amavasya. Half open eyes are called Pratipada Drishti. Poornima Drishti is used in external Trataka, Ama Drishti in inner Trataka and Pratipada Drishti is made use of in Adho Trataka. Nasikagra Drishti, Nasikagra mudra and Shambhavi mudra are also considered to be practices of Adho Trataka.</p>

            <h5>External and internal Trataka on a flame (Candle Flame Trataka)</h5>
            <div className='d-flex justify-content-center'>
              <img src={blogImage3} style={{ width:500, maxWidth: '80%', margin: '1em 0',boxShadow:'0px 0px 5px #0002'}} />
            </div>
            <ul className="about-para mt-3" style={{listStyle:'inside'}}>
              <li className="mt-2" style={{color:'#856'}}>Sit in any meditative asana in which the body can be kept steady. Keep the back and neck in a straight line.</li>
              <li className="mt-2" style={{color:'#856'}}>Light a candle or lamp and place it on a stand at eye level, about an arm's length away from the eyes, so that the back and head can be kept straight when gazing at it.</li>
              <li className="mt-2" style={{color:'#856'}}>Close the eyes and be aware of the whole body. Have the back and neck upright and well aligned. Let the whole body become like a statue. It is essential to be aware of the state of the body. Physical stability is important, as there should be no movement in the body to disturb the concentration.</li>
              <li className="mt-2" style={{color:'#856'}}>Now open the eyes and look at the brightest spot in the of the flame. Just gaze at the flame continuously, forgetting everything else. If the mind becomes unsteady, bring it back and focus on the flame again.</li>
              <li className="mt-2" style={{color:'#856'}}>Different colors will be observed in the candle flame. In the middle the flame is a blue color. Keep looking at it continuously.</li>
              <li className="mt-2" style={{color:'#856'}}>The main effort in Trataka is to avoid twitching or blinking the eyelids. The eyes are kept open for as long as possible without creating any tension, from 30-40 seconds up to two to three minutes.</li>
              <li className="mt-2" style={{color:'#856'}}>Before the eyes develop a burning sensation and tears start flowing, close the eyes and keep them closed until the afterimage of the flame can no longer be seen in chidakasha. If the afterimage cannot be seen at first, do not worry; it will become clear with practice. Just have the thought of the afterimage at the back of the mind. </li>
              <li className="mt-2" style={{color:'#856'}}>If at any time a thought springs up, let it come. Keep on observing the thoughts as a witness. This is essential in the subtle practice of Trataka.</li>
              <li className="mt-2" style={{color:'#856'}}>After each round the eyes are kept closed until their tiredness resolves and the afterimage is no longer seen. When the afterimage becomes faint or disappears, and the eyes are relaxed, open the eyes, and repeat the practice. When intending to finish, close the eyes and when the afterimage has faded continue watching chidakasha, the dark space in front of the closed eyes. Whatever appears there, keep on watching it, but maintain awareness of chidakasha. Practice this for a few minutes. The open the eye extinguish the candle.</li>
            </ul>

            <h5>Time of practice:</h5>
            <p className="about-para mt-3">The ideal time for this practice is early morning or at night.</p>

            <h5>Duration:</h5>
            <p className="about-para mt-3">It is said that if the object of concentration is physical in nature, such as a flame, trataka should not be practiced for more than five minutes. The duration in fact depends on the individual, but it is essential to obtain directions from a very experienced guide i intending to practise for longer. Five minutes of practice is sufficient to remove some types of eye defects.</p>

            <h5>Precautions:</h5> 
            <p className="about-para mt-3">Do not practice Trataka on a flame that is not steady. People with visual problems should ensure that the symbol is positioned so that a double image is not seen and should seek expert guidance if this is not possible. Trataka should not be practised wearing spectacles. Although the texts specifically mention tears, notice that the wording indicates practising till the threshold of this experience. The point of the practice is not to make tears roll down the face, but simply to practise if one can without this happening.</p>

            <h5>Contra-indications:</h5> 
            <p className="about-para mt-3">People with glaucoma should not practise trataka. Epileptics should not practise Trataka on a candle flame. Trataka should not be practised on the sun.</p>

            <h3 className='mt-3 text-primary mb-4' style={{}}>Benefits</h3>

            <p className="about-para mt-3">Sage Gheranda says three things in this respect. First, Netrarogaa vinashyanti, Trataka removes defects of the eyes. This is its physical benefit. Second, Divyadrishtik prajaayate, divine vision is created. This is its divine benefit. Third, Shaambhavee jaayate dhruvam, the siddhi of shambhavi is achieved. This is its spiritual benefit. Vision has a direct link with the state of consciousness. Conflicting thoughts lead to mental restlessness and instability. Trataka is the best way to manage this condition. By stabilizing the eyes during trataka the consciousness, chitta, can be made steady and free of tension. When the chitta becomes one-pointed, the pineal gland is energized, and a higher state is achieved.</p>

            <p className="about-para mt-3">Trataka is a simple way to increase memory. It is very useful for people suffering from insomnia and nervous tension. Trataka builds self-confidence. It also influences the brainwaves, which change according to our physical, mental and emotional activities. The frequency of these waves slowly reduces with trataka. The mind becomes thoughtless and free from disorders and freedom from emotional ailments is attained. The practice increases awareness and calms all dimensions of inner activity, thus releasing energy</p>

            <p className="about-para mt-3">Trataka is the best practice for clearing the purifying the antahkarana (inner vision), the inner tool of consciousness. It is also believed that with the practice of Trataka, when the antahkarana becomes fixed on an object or a symbol and merges with it, laya samadhi is attained. With the attainment of laya samadhi, many kinds of siddhis are attained. This was noted in the life of Sage Gorakhnath, who attained siddhis through the medium of Trataka.</p>

            <div className='d-flex justify-content-center'>
              <img src={blogImage4} style={{ width:500, maxWidth: '80%', margin: '1em 0',boxShadow:'0px 0px 5px #8763A2'}} />
            </div>
            
            <h3 className='mt-3 text-primary mb-4' style={{}}>Other benefits of Trataka</h3>

            <ul className="about-para mt-3">
              <li className="mt-2" style={{color:'#856'}}>The trataka kriya strengthens eye muscles and improves vision and memory.</li>
              <li className="mt-2" style={{color:'#856'}}>Develops deep concentration and meditative power of the mind.</li>
              <li className="mt-2" style={{color:'#856'}}>Calms the mind by removing vrittis (negative thought patterns) and prevent it from wandering here and there.</li>
              <li className="mt-2" style={{color:'#856'}}>Cure all types of eye-related problems, e.g., eyestrain, headache, long and short-sightedness, astigmatism, etc.</li>
              <li className="mt-2" style={{color:'#856'}}>Boosts the determination and can treat depression.</li>
              <li className="mt-2" style={{color:'#856'}}>Quality of sleep gets improved with trataka practice.</li>
              <li className="mt-2" style={{color:'#856'}}>Trataka practice helps in achieving a state of complete peacefulness.</li>
              <li className="mt-2" style={{color:'#856'}}>Trataka helps in achieving the highest state of consciousness.</li>
            </ul>

            <h3 className='mt-3 text-primary mb-4' style={{}}>Conclusion</h3>

            <p className="text-danger about-para mt-3 text-center">
              <strong>“By performing beautiful technique of Trataka, you can attain complete calmness and peacefulness of mind with increased cognitive functions”</strong></p>

            <p className="about-para mt-3">Hence, Trataka Kriya is a treasure mentioned in various scriptures of Yoga. It is an art to awaken the third eye- the inner eye of knowledge. In this dark world of Kali-yuga, where everyone is engrossed in their problems and struggle to do better in life, this traditional technique can become a ladder to reach the ultimate state of awakening. It can rejuvenate the consciousness and aid in the union of higher and lower selves. By regularly practicing Trataka, you can achieve absolute happiness and can organize the dissipated and scattered energy of the senses and emotions. Trataka not only enhances the concentration and focus but also teaches to see beyond the external appearances. It polishes your intellect and allows you to develop insights and wisdom.</p>

          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
