import React, { useEffect, useState } from 'react';
import './index.css'
import Progress_bar from '../../component/Progress_bar'
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { apiCall } from '../../api/apiclient';
import jwt_decode from 'jwt-decode';
import { fontSize } from '@mui/system';
import swal from "sweetalert";

const Csqv = () => {
    const [questions, setQuestions] = useState([])

    const navigate = useNavigate()

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showIfScreeningNone, setShowIfScreeningNone] = useState(false);
    const [showFinish, setShowFinish] = useState(false);
    const [score, setScore] = useState(0);
    const [cvsqResult, setCvsqResult] = useState(false);
    const [isMcqPassed,setIsMcqPassed] = useState(false);

    const [showIntessityIndex,setShowIntessityIndex] = useState('');

    const [active, setActive] = useState(null);
    const [active2, setActive2] = useState(null);

    const _data = localStorage.getItem("data");
    const decoded_data = jwt_decode(_data)

    const handleFirstOptionClick = (index) =>{
        setShowIntessityIndex(index);
        setActive(index);
        setActive2(null);
    }

    const handleAnswerOptionClick = (isCorrect, index,index2, mark, qID) => {
        //calculate the marks and then set
        setScore();
        setActive2(index2);
    };

    const [activeSelectError, setActiveSelectError] = useState(false)

    const nextFunciton = async (data) => {
        if (active === null) return setActiveSelectError(true);
        setActiveSelectError(false);
        // console.log(data);
        const qIDP = questions[currentQuestion + 1]._id;

        // lets find out the frequescy and intensity 
        const frequency_id = active;

        const intensity_id = active2

        let marks = 0;

        if(active !== 0){
          let a = data.frequency[active].mark;
          let b = data.frequency[active].intensity[active2].mark;
            marks = a * b;
            // new login suggested by priyanka ma'am
            if(marks > 1){
              marks = marks / 2;
            }
        }


        //call for next
        const sendData = {
            "u_id": decoded_data.user._id,
            "q_id": data._id,
            "q_idPlus": qIDP,
            "frequency": frequency_id,
            "intensity":intensity_id + 1,
            "score": marks,
            "tokens": _data,
        }
        const res = await apiCall('/api/cvsqresponse/insert', sendData)
        // console.log(sendData);
        setActive(null);
        setActive2(null);
        setShowIntessityIndex('');
        if (res.dataPlus) { setActive(res.dataPlus.answer) }
        else setActive(null);

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowFinish(true);
        }
    }

    const backFunciton = async (data) => {

        const sendData = {
            "u_id": decoded_data.user._id,
            "q_id": data._id,
            "tokens": _data,
        }

        const res = await apiCall('/api/cvsqresponse/get', sendData)
        setActive(res.data.answer)

        setActiveSelectError(false)
        const previousQuestion = currentQuestion - 1;

        if (previousQuestion < questions.length) {
            setCurrentQuestion(previousQuestion);
        }
    }

    // final Submit
    const finalSubmit = async (data) => {
        if (active === null ) return setActiveSelectError(true);
        setActiveSelectError(false)

         // lets find out the frequescy and intensity 
         const frequency_id = active;

         const intensity_id = active2
 
         let marks = 0;
 
         if(active !== 0){
             marks = data.frequency[active].mark * data.frequency[active].intensity[active2].mark ;
         }

        const sendData = {
            "u_id": decoded_data.user._id,
            "q_id": data._id,
            "frequency": frequency_id,
            "intensity": intensity_id + 1,
            "score": marks,
            "tokens": _data,
        }
        const res = await apiCall('/api/cvsqresponse/final/submit', sendData);
        if(res.message === 'success'){
          setShowFinish(true);
          if(res.data.result_status){
            setCvsqResult(true);
          }
        }
    }

    // For progress Bar
    const percent = ((currentQuestion / questions.length) * 100).toFixed(0);

    // if user already completed the mcq test then redirect to csvq
    const redirectTocsvq = () => {
        setTimeout(() => navigate('/'),3000);
    }

    // toredirect to mcq if he/she not completed the mqc test
    const redirectTomcq = () => {
        navigate('/test/mcq');
    }

    // get questions
    const getQuestions = async () => {

        const sendData = {
            "u_id": decoded_data.user._id,
            "tokens": _data,
        }

        // here i have to check if the user 
        const pass = await apiCall("/api/screeningresponse/get/mcqresult",sendData);
        if(pass.data){
            setIsMcqPassed(pass.data.result_status);
        }
        
        // if(pass.data.result_status){

            const data = await apiCall("/api/cvsqquestion/get", sendData)
            if (data.checkFirstAvailibility) {
                // then user has finished screening test ,AND
                if (data.checkAvailibility) {
                    setQuestions(data.data)
                    // console.log(data.data);
                    setShowFinish(true)
                    if(data.checkAvailibility.result_status){
                      setCvsqResult(true);
                    }
                }
                else
                    setQuestions(data.data)
            } else {
                // screening not finished
                setShowIfScreeningNone(true)
            }

        // }

    }


    useEffect(() => {
        getQuestions()
    }, [])

    return (
      <div className="main-container">
        <div className="quiz-container">
          <p className="header-quiz">CVSQ Test</p>
          <div className="app">
            {showIfScreeningNone ? (
              <>
                <div className="score-section">
                  You Haven't completed the Screening Test! <br /> Please, First
                  complete the{' '}
                  <span className="submitted-text" onClick={redirectTomcq}>
                    {' '}
                    Screening test!
                  </span>
                </div>
              </>
            ) : (
              <>
                {isMcqPassed == 1 ? (
                  <>
                    {!showFinish && (
                      <Progress_bar
                        bgcolor="orange"
                        progress={percent}
                        height={20}
                      />
                    )}
                    {activeSelectError && (
                      <p className="errror-on-option">
                        Please select from the options!
                      </p>
                    )}
                    {showFinish ? (
                      <div className="score-section">
                        You have successfully submitted all the{' '}
                        {questions.length} questions!{' '}
                        <div style={{padding:'5px 10px', background:'#000',margin:'5px 0', width:'100%'}}>Result :  {cvsqResult ? <span style={{color:'#03fcd3' }}>You are eligible for further screening proccess</span> : <span style={{color:'red'}}>You are not eligible</span>}</div>
                        
                        <span
                          className="submitted-text"
                          onClick={() => navigate('/')}
                        >
                          {cvsqResult ? 'The Result will be sent to you through sms and email along With a link. ThankYou ...' : ''}
                        </span>
                      </div>
                    ) : (
                      <div className="card-question-container">
                        <div className="question-section">
                          <div className="question-count">
                            {questions.length != 0 && (
                              <>
                                <span className="question">
                                  Question {currentQuestion + 1}
                                </span>
                                /{questions.length}{' '}
                              </>
                            )}
                          </div>
                          {questions.length != 0 && (
                            <div className="question-text">
                              {questions[currentQuestion].questions}
                            </div>
                          )}
                        </div>
                        <div className="answer-section">
                          {questions.length != 0 &&
                            questions[currentQuestion].frequency.map(
                              (answerOption, index) => (
                                <>
                                  <button
                                    key={index}
                                    id={index}
                                    className={
                                      active === index
                                        ? 'active btn-option bg-secondary'
                                        : 'btn-option'
                                    }
                                    onClick={() =>
                                      handleFirstOptionClick(index)
                                    }
                                  >
                                    {answerOption.option}
                                  </button>

                                  {index !== 0 &&
                                    showIntessityIndex == index && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        {answerOption.intensity.map(
                                          (el, index2) => (
                                            <button
                                              className={
                                                active2 === index2
                                                  ? 'active2 btn-option2 bg-secondary'
                                                  : 'btn-option2'
                                              }
                                              onClick={() =>
                                                handleAnswerOptionClick(
                                                  el.isCorrect,
                                                  index,
                                                  index2,
                                                  el.mark,
                                                  questions[currentQuestion],
                                                )
                                              }
                                            >
                                              {el.option}
                                            </button>
                                          ),
                                        )}
                                      </div>
                                    )}
                                </>
                              ),
                            )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="score-section">
                    You Haven't completed the Screening Test! <br /> Please,
                    First complete the{' '}
                    <span className="submitted-text" onClick={redirectTomcq}>
                      {' '}
                      Screening test!
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {!showIfScreeningNone && (
          <>
            {!showFinish && (
              <div className="next-prev-container">
                {currentQuestion === 0 ? (
                  <div></div>
                ) : (
                  <Button
                    className="common-on-btn back-btn"
                    onClick={() => backFunciton(questions[currentQuestion - 1])}
                  >
                    Back
                  </Button>
                )}

                {/* checking if the user in the last question */}
                {currentQuestion === questions.length - 1 ? (
                  //user in the last question
                  <>
                    {active === 0 ? (
                      //selected the never option
                      <Button className='common-on-btn bg-primary' onClick={() => finalSubmit(questions[currentQuestion])}>Final Sumbit</Button>
                    ) : (
                      // selected occationally of often/always
                      <>
                        {active !== null && active2 !== null && (
                          <Button className='common-on-btn bg-primary' onClick={() => finalSubmit(questions[currentQuestion])}>Final Sumbit</Button>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  //user not in the last question
                  <>
                    {active === 0 ? (
                      //selected the never option
                      <Button
                        className="common-on-btn next-btn"
                        onClick={() => nextFunciton(questions[currentQuestion])}
                      >
                        Next
                      </Button>
                    ) : (
                      // selected occationally of often/always
                      <>
                        {active !== null && active2 !== null && (
                          <Button
                            className="common-on-btn next-btn"
                            onClick={() =>
                              nextFunciton(questions[currentQuestion])
                            }
                          >
                            Next
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
}

export default Csqv