import React, { useEffect, useState } from 'react'
import { apiCall } from '../../api/apiclient';


import "./index.css"

function Glossary() {
    const [isLoaded,setIsLoaded] = useState(false);
    const [data,setData] = useState([])


    useEffect(() => {
     if(!isLoaded){
        fetchData();
     }
    }, [isLoaded])

    const fetchData = async () => {
       const data = await apiCall('/api/glossary/getAll');
       setData(data.data);
       setIsLoaded(true);
    }
    
    return (
        <>
            <div className="glossary__container">
                {isLoaded && (
                    data.map((item, index) => (
                        <div className="flip-card" key={index}>
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <h3>{item.Tittle}</h3>
                                </div>
                                <div className="flip-card-back">
                                    <p>{item.Content}</p>
                                    {item.Link_url && item.Link_url != 'NULL' && item.Link_url != '' && (<a href={item.Link_url} target="_blank" rel="noreferrer">Read More</a>)}
                                </div>
                            </div>
                        </div>
                    ))
                )
                }
            </div>
        </>
    )
}

export default Glossary