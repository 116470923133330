import React, { useState, useEffect } from "react";
import "../../asset/css/profile.css";
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { Card, Col, Row } from 'antd';
import '../../asset/css/dashboard.css'
import { UserAddOutlined } from "@ant-design/icons";

const AdminDashboard = () => {


    function redirectToTeachers() {
        window.location.href = '/dashboard/teachers'
    }

    function redirectToAttendance() {
        window.location.href = '/dashboard/teachers/attendance'
    }
    function redirectToRegitration() {
        window.location.href = '/dashboard/teachers/registration'
    }

    const styles = {
        cardStyle: {
            boxShadow: "1px 2px 1px #9E9E9E"
        }
    };

    const [data, setData] = useState()

    const _data = JSON.parse(localStorage.getItem('data'))
    
    // edit this function to fetch all the summary 
    const getDetails = async () => {
        const date = moment(new Date()).format("YYYY-MM-DD")

        const URL = window.location.href.startsWith("http://localhost")
            ? "http://localhost:5000/api/admin/getSummary"
            : "/api/admin/getSummary";

        await fetch(URL, {
            method: 'POST',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "tokens": _data
            })
        })
            .then(async response => {
                try {
                    if (response.status !== 200) {
                        console.log("Not 200")
                    } else {
                        const data = await response.json()
                        setData(data.data)
                    }
                } catch (err) {
                    console.log(err);
                };
            })
            .catch(err => {
                console.log(err);
            });
    };


    useEffect(() => {
        // instead of calling multiple call once 
        getDetails();
    }, [])

    return (
        <div>
            {data && (
            <div className="row">
                <div className="col-lg-4">
                    <div className="card-container">
                        <p className="card-header">Total users</p>
                        <div className="container-alignment">
                            <div className="img-container">
                                <UserAddOutlined style={{ fontSize: "30px" }} />
                            </div>
                            <div className="col-container">
                                <p className="div1">{data.AllUserCount}</p>
                                <p className="div2">Registered Users</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card-container">
                        <p className="card-header">Screening Test</p>
                        <div className="screening-container">
                            <div className="col-container first">
                                <p className="sub-heading">Passed</p>
                                <p className="div1">{data.AllScreeningPassed}</p>
                            </div>
                            <div class="vertical-line"></div>
                            <div className="col-container">
                                <p className="sub-heading">Failed</p>
                                <p className="div1">{data.AllScreeningFailed}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card-container">
                        <p className="card-header">CSVQ Test</p>
                        <div className="screening-container">
                            <div className="col-container first">
                                <p className="sub-heading">Passed</p>
                                <p className="div1">{data.AllCvsqPassed}</p>
                            </div>
                            <div class="vertical-line"></div>
                            <div className="col-container">
                                <p className="sub-heading">Failed</p>
                                <p className="div1">{data.AllCvsqFailed}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            )}


            <Row style={{ justifyContent: "space-evenly" }}>
                {/* <Card onClick={redirectToTeachers} type="inner" hoverable={true} title="Total Employees" bordered={false} style={styles.cardStyle} className="card-styles">
                    <p className="profile-num">Registered Users:  {data ? (data[0].length === 0 ? "No User" : data[0].length) : "Loading......"}</p>
                </Card>

                <Card onClick={redirectToAttendance} type="inner" hoverable={true} title="Today's Attendance" bordered={false} style={styles.cardStyle} className="card-styles">
                    <p className="profile-num">Attendance given by:  {data ? data[1].length === 0 ? "Not Yet" : data[1].length + " users" : "Loading......"}</p>
                </Card>

                <Card onClick={redirectToRegitration} type="inner" hoverable={true} title="Register New User" bordered={false} style={styles.cardStyle} className="card-styles">
                    <p className="profile-num">Newly Added Teacher Today:  {data ? data[2].length === 0 ? "0" : data[2].length : "Loading......"}</p>
                </Card> */}

            </Row>
        </div>
    )
}

export default AdminDashboard