import React, { useEffect, useState } from 'react';
import './index.css'
import Progress_bar from '../../component/Progress_bar'
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { apiCall } from '../../api/apiclient';
import jwt_decode from 'jwt-decode';
import '../../assests/css/question.css';

const Mcq = () => {
    const [questions, setQuestions] = useState([])

    const navigate = useNavigate()

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [score, setScore] = useState(0);
    const [isPassed,setIsPassed] = useState(false);

    const [active, setActive] = useState(null);

    const _data = localStorage.getItem("data");
    const decoded_data = jwt_decode(_data)

    const handleAnswerOptionClick = (isCorrect, index, mark, qID) => {
        setScore(mark);
        setActive(index);
    };

    const [activeSelectError, setActiveSelectError] = useState(false)

    const nextFunciton = async (data) => {
        if (active === null) return setActiveSelectError(true);
        setActiveSelectError(false);

        const qIDP = questions[currentQuestion + 1]._id;

        //call for next
        const sendData = {
            "u_id": decoded_data.user._id,
            "q_id": data._id,
            "q_idPlus": qIDP,
            "answer": active,
            "score": score,
            "tokens": _data,
        }
        const res = await apiCall('/api/screeningresponse/insert', sendData)

        if (res.dataPlus) setActive(res.dataPlus.answer)
        else setActive(null)

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    }

    const backFunciton = async (data) => {

        const sendData = {
            "u_id": decoded_data.user._id,
            "q_id": data._id,
            "tokens": _data,
        }

        const res = await apiCall('/api/screeningresponse/get', sendData)
        setActive(res.data.answer)

        setActiveSelectError(false)
        const previousQuestion = currentQuestion - 1;

        if (previousQuestion < questions.length) {
            setCurrentQuestion(previousQuestion);
        }
    }

    // final Submit
    const finalSubmit = async (data) => {
        if (active === null) return setActiveSelectError(true);
        setActiveSelectError(false)
        setShowScore(true);

        const sendData = {
            "u_id": decoded_data.user._id,
            "q_id": data._id,
            "answer": active,
            "score": score,
            "tokens": _data,
        }
        const res = await apiCall('/api/screeningresponse/final/submit', sendData)
        console.log(res);
        getQuestions();
    }

    // For progress Bar
    const percent = ((currentQuestion / questions.length) * 100).toFixed(0);

    const redirectTocsvq = () => {
        navigate('/test/csvq');
    }


    // get questions
    const getQuestions = async () => {

        // first check if the user passes in the previous attemtps
        const sendData = {
            "u_id": decoded_data.user._id,
            "tokens": _data,
        }

        const pass = await apiCall("/api/screeningresponse/get/mcqresult",sendData);
        if(pass.data){
            setIsPassed(pass.data.result_status);
        }

        const data = await apiCall("/api/screeningquestion/get", sendData)
        if (data.checkAvailibility) {
            setQuestions(data.data)
            setShowScore(true)
        }
        else
            setQuestions(data.data)
    }

    useEffect(() => {
        getQuestions()
    }, [])

    return (
        <div className='main-container'>
            <div className='quiz-container'>
                <p className='header-quiz'>Screening Test 1</p>
                <div className='app' style={{width:'80%'}}>
                    {!showScore && <p className='text-warning text-center' style={{fontWeight:'500'}}>Click on the highlighed word to view the meaning !</p>}
                    {!showScore && <Progress_bar bgcolor="orange" progress={percent} height={20} />}
                    {activeSelectError && <p className='errror-on-option'>Please select from the options!</p>}
                    {showScore ? (
                        <>
                        {isPassed ? (
                            <div className='score-section'>
                            You have successfully submitted all the {questions.length} questions!
                            <br/>
                            <div>Result <span style={{color:'green',fontWeight:'600'}}>eligible for CVSQ</span></div> 
                            <br/>
                            <span className='submitted-text' onClick={redirectTocsvq}>You may move on to the CSVQ test.</span>
                        </div>
                        ):(
                            <div className='score-section'>
                               You have successfully submitted all the {questions.length} questions! <br/>
                               <div>Result <span style={{color:'red',fontWeight:'600'}}>You are not eligible For CVSQ test.</span></div> 
                            </div>
                        )}
                        
                        </>
                    ) : (
                        <div className='card-question-container'>
                            <div className='question-section'>
                                <div className='question-count'>
                                    {questions.length != 0 && <><span className='question'>Question {currentQuestion + 1}</span>/{questions.length} </>}
                                </div>
                                {questions.length != 0 && <div className='question-text' dangerouslySetInnerHTML={{__html:questions[currentQuestion].questions}}></div>}
                            </div>
                            <div className='answer-section'>
                                {questions.length != 0 && questions[currentQuestion].options.map((answerOption, index) => (
                                    <button key={index} id={index} className={active === index ? "active btn-option bg-secondary" : "btn-option"} onClick={() => handleAnswerOptionClick(answerOption.isCorrect, index, answerOption.mark, questions[currentQuestion])}>{answerOption.option}</button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {!showScore && <div className='next-prev-container'>
                {currentQuestion === 0 ? <div></div> : <Button className='common-on-btn back-btn' onClick={() => backFunciton(questions[currentQuestion - 1])}>Back</Button>}
                {currentQuestion === questions.length - 1 ? <Button className='common-on-btn bg-primary' onClick={() => finalSubmit(questions[currentQuestion])}>Final Sumbit</Button>
                    :
                    <Button className='common-on-btn next-btn' onClick={() => nextFunciton(questions[currentQuestion])}>Next</Button>}
            </div>}
        </div>
    )
}

export default Mcq