import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';

import * as AiIcons from 'react-icons/ai';
import { Sidebar_data } from './Sidebar_data';

// import TopBar from '../staticObject/TopBar';// Import your Sidebar_data array

const { Header, Content, Footer, Sider } = Layout;

export default function Dashbord_user() {
    const [collapsed, setCollapsed] = useState(false);
    const [currentLinkIndex, setCurrentLinkIndex] = useState('1');

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    breakpoint="lg"
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                >
                    <div className="avatar">
                        <div style={{ color: 'white', fontWeight: 'bold', marginRight: '5px', textAlign: 'center', fontSize: '24px'}}>
                            Tratak Kriya
                        </div>
                    </div>

                    <Menu
                        theme="dark"
                        selectedKeys={[currentLinkIndex]}
                        mode="inline"
                        onClick={(val) => setCurrentLinkIndex(val.key)}
                    >
                        {Sidebar_data.map((item, index) => (
                            <Menu.Item key={index + 1} icon={item.icon}>
                                <Link to={item.path}>{item.title}</Link>
                            </Menu.Item>
                        ))}
                    </Menu>
                </Sider>

                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        {/* Add your TopBar component here */}
                                
                        
                    </Header>

                    <Content style={{ margin: '0' }}>
                           
                        <div className="site-layout-background" style={{ padding: 0, minHeight: 0 }}>
                            <Outlet />
                        </div>
                    </Content>

                    
                </Layout>
            </Layout>
        </div>
    );
}
