import React, { useState, useEffect } from 'react';
import '../../admin/asset/css/profile.css';
import avatar from '../../admin/asset/images/avataar.png';
import '../../admin/asset/css/ViewProfile.css';
import jwt_decode from 'jwt-decode';
import { Avatar, Select } from 'antd';
import { DeviceUUID } from 'device-uuid';
import { Form, Input } from 'antd';
import { Option } from "antd/es/mentions";
import swal from 'sweetalert';
import { states } from "../../glossary_Data/states2";


const UserProfileDetail = () => {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [height, setHeight] = useState('');
  const [institute, setInstitute] = useState('');
  const [course, setCourse] = useState('');
  const [state, setState] = useState('');
  const [user, setUser] = useState('');
  const [createdAt, setCtreatedAt] = useState('');
  const [isDisable, setIsDisable] = useState(true);
  const [profileImg, setProfileImg] = useState();
  const logCheck = localStorage.getItem('data');
  const decoded_data = jwt_decode(logCheck);

  // get individual user details
  const getSingleUserDetails = async () => {
    //get the data
    const _data = await JSON.parse(localStorage.getItem('data'));
    const URL = window.location.href.startsWith('http://localhost')
      ? 'http://localhost:5000/api/user/get/user/data'
      : ' /api/user/get/user/data';

    await fetch(URL, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tokens: _data,
        uid: decoded_data.user.uid,
      }),
    })
      .then(async (response) => {
        try {
          if (response.status !== 200) {
            console.log('Not 200');
          } else {
            const data = await response.json();
            console.log(data);
            setFirstName(data.data[0].first_name);
            setMiddleName(data.data[0].middle_name);
            setLastName(data.data[0].last_name);
            setEmail(data.data[0].email);
            setPhone(data.data[0].phone);
            setGender(data.data[0].gender);
            setAge(data.data[0].age);
            setHeight(data.data[0].height);
            setInstitute(data.data[0].institute);
            setCourse(data.data[0].course);
            setState(data.data[0].state);
            setCtreatedAt(data.data[0].created_at);
            setProfileImg(data.data[0].profile_img);
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uuid = new DeviceUUID().parse();
  useEffect(() => {
    getSingleUserDetails();
  }, []);

  const onFinish = async () => {
    const _data = await JSON.parse(localStorage.getItem('data'));
    const URL = window.location.href.startsWith('http://localhost')
      ? 'http://localhost:5000/api/rol/user/update'
      : '/api/rol/user/update';

    await fetch(URL, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tokens: _data,
        uid: decoded_data.user.uid,
        firstname: firstName,
        middlename: middleName,
        lastname: lastName,
        email,
        phone,
        gender,
        height,
        age,
        institute,
        course,
        state,
      }),
    })
      .then(async (response) => {
        try {
          if (response.status !== 200) {
            console.log('Not 200');
          } else {
            const data = await response.json();
            if (data.message === 'success') {
              swal('Saved successfully', {
                buttons: false,
                timer: 1300,
              });
            } else {
              swal('Failed To Save Profile !', {
                buttons: false,
                timer: 1300,
              });
            }
          }
        } catch (err) {
          console.log(err);
          swal('Failed To Save Profile !', {
            buttons: false,
            timer: 1300,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        swal('Failed To Save Profile !', {
          buttons: false,
          timer: 1300,
        });
      });
  };

  const UpdateData = () => {
    setIsDisable(true);
    // alert('sent data to server');
    onFinish();
  };

  

  return (
    <div className="profile-container">
      <div className="row">
        <div className="col-lg-3">
          <div className="p-img-container">
            <Avatar
              className="profile-image"
              sx={{
                height: '120px',
                width: '120px',
                marginBottom: '5px',
                marginTop: '10px',
              }}
              alt="profile_image"
              src={
                profileImg
                  ? 'https://tratakkriyapractice.co.in' + profileImg
                  : avatar
              }
            />
            
            <div className="data mt-3">
              <p
                style={{
                  fontSize: '14px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                Welcome&nbsp;
                <span className="text-primary" style={{}}>
                  {firstName ? firstName : 'NA'}&nbsp;
                  {middleName ? middleName : ''}&nbsp;
                  {lastName ? lastName : 'NA'}&nbsp;!
                </span>
              </p>
              <p style={{ fontSize: '14px' }}>
                Profile Created{' '}
                <span className="text-primary">
                  {createdAt ? createdAt : 'NA'}
                </span>
              </p>
            </div>
            {isDisable ? (
              <button
                className="btn btn-primary my-4"
                onClick={() => setIsDisable(false)}
              >
                Update Profile
              </button>
            ) : (
              <button
                className="btn btn-warning my-4"
                onClick={() => UpdateData()}
              >
                Save Profile
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-9 p-2">
          <div className="row second-container">
            <Form
              name="profile"
              initialValues={user}
              labelCol={
                {
                  // span: uuid.platform === "Android" || uuid.platform === "iPhone" ? 10 : 5
                }
              }
              wrapperCol={{
                span:
                  uuid.platform === 'Android' || uuid.platform === 'iPhone'
                    ? '100%'
                    : '20em',
              }}
              layout={
                uuid.platform === 'Android' || uuid.platform === 'iPhone'
                  ? 'vertical'
                  : 'vertical'
              }
              // onFinish={onFinish}
              // onValuesChange={(e)=>changeForm(e)}
              // onFinishFailed={onFinishFailed}
              // layout='inline'
              autoComplete="off"
              fields={[
                {
                  name: ['first_name'],
                  value: firstName,
                },
                {
                  name: ['middle_name'],
                  value: middleName,
                },
                {
                  name: ['last_name'],
                  value: lastName,
                },
                {
                  name: ['email'],
                  value: email,
                },
                {
                  name: ['phone'],
                  value: phone,
                },
                {
                  name: ['gender'],
                  value: gender,
                },
                {
                  name: ['height'],
                  value: height,
                },
                {
                  name: ['age'],
                  value: age,
                },
                {
                  name: ['institute'],
                  value: institute,
                },
                {
                  name: ['course'],
                  value: course,
                },
                {
                  name: ['state'],
                  value: state,
                },
              ]}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Form.Item name="first_name" label="First Name">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="middle_name" label="Middle Name">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="last_name" label="Last Name">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Item>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Form.Item name="email" label="Email" style={{ width: '30%' }}>
                  <Input
                    disabled={true}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="phone" label="Phone Number">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Item>

                <Form.Item name="gender" label="Gender">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setGender(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="height" label="Height">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </Form.Item>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Form.Item name="age" label="Age">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setAge(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="institute" label="Institute">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setInstitute(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="course" label="Course">
                  <Input
                    disabled={isDisable}
                    onChange={(e) => setCourse(e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="state" label="State" style={{width:'25%'}}>
                  <Select
                    placeholder="Change Your State"
                    disabled={isDisable}
                    allowClear
                    options={states}
                    style={{fontWeight:600}}
                  >
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileDetail;
