import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import './testcontainer.css';

function TestContainer() {
    return (
        <>
            <div className="testContainer container-fluid">
                {/* ypu can shift this to left side */}
                <div className='div1'>
                    <div className='div2'>
                        <Link to="mcq" >
                            <div className='div3'>
                                <img src="https://www.cancer.gov/sites/g/files/xnrzdm211/files/styles/cgov_social_media/public/cgov_image/media_image/100/500/7/files/cancer-screening-test-article.jpg" alt="screening test" />
                                <div className='div4'>Screening MCQ</div>
                            </div>
                        </Link>
                    </div>

                    <div className='div2'>
                        <Link to="csvq">
                            <div className='div3'>
                                <img src="https://www.cancer.gov/sites/g/files/xnrzdm211/files/styles/cgov_social_media/public/cgov_image/media_image/100/500/7/files/cancer-screening-test-article.jpg" alt="screening test" />
                                <div className='div4'>CVSQ</div>
                            </div>
                        </Link>
                    </div>

                </div>
                <div className='container outlet-container'>
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default TestContainer