import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as AiIcons from 'react-icons/ai';
import { FaUser } from "react-icons/fa";
import { GiMeditation } from 'react-icons/gi';
import { BiUserDetail } from 'react-icons/bi';
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';


export const Sidebar_data = [

  {
    title: 'Tratak-Kriya',
    path: '/dashboard_user',
    icon: <GiMeditation />,
    cName: 'nav-text',
  },
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text',
  },
  {
    title: 'User-Details',
    path: '/user/profile',
    icon: <FaUser />,
    cName: 'nav-text',
  },
  // {
  //   title: 'Product',
  //   path: 'tratak',
  //   icon: <AiIcons.AiFillHome />,
  //   cName: 'nav-text',
  // },
  
];
