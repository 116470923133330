import React from 'react'

const Progress_bar = ({ bgcolor, progress, height }) => {

    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: 'cyan',
        borderRadius: 40,
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius: 40,
        textAlign: 'right',

        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }

    const ChilddivZero = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius: 40,
        textAlign: 'right',

    }

    const progresstext = {
        // padding: 10,
        color: 'black',
        fontSize: "13px",
        fontWeight: 700,
    }

    return (
        <div style={Parentdiv}>
            {progress === 0
                ? <div style={ChilddivZero}>
                    <span style={progresstext}>{`${progress}%`}</span>
                </div>
                :
                <div style={Childdiv}>
                    <span style={progresstext}>{`${progress}%`}</span>
                </div>}
        </div>
    )
}

export default Progress_bar;
