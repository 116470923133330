import { React } from "react";
import { NavLink } from "react-router-dom";
import Home from "./Home";

const Errorpage = () => {
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          <h1>404</h1>
        </div>
        <NavLink to="/">Back to Home</NavLink>
      </div>
    </>
  );
};

export default Errorpage;
