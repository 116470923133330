import React from "react";
import "../assests/css/ban.css";
import "../assests/css/pages.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Banner() {
  const checkScreeningTest = () => {
    const logCheck = localStorage.getItem("data");
    if (logCheck) window.location.href = "/test";
    else window.location.href = "/login";
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `https://drive.google.com/file/d/10xI4BckJRaB1_7iWxgjs6_VuveuqLPpB/view?usp=sharing`; // Replace YOUR_FILE_ID with the actual file ID
    link.target = "_blank";
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Remove after download
  };
  return (
    <div className="banner">
      <div className="container-fluid p-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6">
            <div className="col col-md-12">
              <h1 className="home-banner">Tratak Kriya</h1>
            </div>
            <div className="col col-md-12">
              <p className="p-banner">
                Artificial Intelligence based assessment of Tratak Kriya in
                Computer Vision Syndrome
              </p>
            </div>
            <div className="col col-md-12">
              <div className="row mt-4">
                <div className="col-md-6">
                  <p className="banner-list">
                    <i>
                      <FontAwesomeIcon icon={faCheck} className="banner-icon" />
                    </i>
                    Release Visual Discomfort
                  </p>
                  <p className="banner-list">
                    <i>
                      <FontAwesomeIcon icon={faCheck} className="banner-icon" />
                    </i>
                    Relive Eyestrain
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="banner-list">
                    <i>
                      <FontAwesomeIcon icon={faCheck} className="banner-icon" />
                    </i>
                    Improve Vision
                  </p>
                  <p className="banner-list">
                    <i>
                      <FontAwesomeIcon icon={faCheck} className="banner-icon" />
                    </i>
                    Reliveing Nervous Tension
                  </p>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary text-uppercase screening-btn"
              onClick={() => checkScreeningTest()}
            >
              START SCREENING TEST
            </button>
            <Link to="/quick">
              <button
                type="button"
                className="btn btn-primary text-uppercase screening-btn quick "
              >
                QUICK SURVEY
              </button>
            </Link>

            <button
              type="button"
              className="btn btn-primary text-uppercase screening-btn quick "
              onClick={handleDownload}
              style={{ marginLeft: "0px", border: "0" }}
            >
              USER GUIDE
            </button>
          </div>

          <div className="col-lg-6 banner-second-box">
            <iframe
              style={{ borderRadius: "10px" }}
              width="100%"
              height="320"
              src="https://www.youtube.com/embed/BKEEoE7746Y"
            ></iframe>
            <span style={{ color: "white" }}>
              For Better Understanding Go Through The Video
            </span>
            {/* <img
                src="Screenshot.png"
                alt="Description of the image"
                style={{ borderRadius: "10px", width: "100%", height: "320px" }}
              /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
